import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { trackEvent } from '../../../../extra/sharedMethods';
import { AllReferencesSummary } from '../../../../models/QAmodels';
import {
  QAControllerEventArgs,
  useQAController,
} from '../../../../scripts/QAController';
import { AnalyticsEvent } from '../../../../scripts/constants/analytics-event';
import { useSidebarOpen } from '../../../../scripts/hooks';
import { toggleSidebar } from '../../../../scripts/sidebar';
import { UIIcon } from '../../../controls/ui/UIIcon/UIIcon';
import { UIIconButton } from '../../../controls/ui/UIIconButton/UIIconButton';
import { TabDefinition, UITabs } from '../../../controls/ui/UITabs/UITabs';
import { ReferenceIcon } from '../QAReferenceUtil';
import { AppTabContent } from './AppTabContent';

export const QAReferencesSidecar: FC = () => {
  const [open, setOpen] = useState(false);
  const [questionId, setQuestionId] = useState('');
  const [sidebarToggled, setSidebarToggled] = useState(false);

  const isSidebarOpen = useSidebarOpen();

  const [allReferencesSummary, setAllReferencesSummary] =
    useState<AllReferencesSummary>({
      app_references_count: [],
    });

  const qaController = useQAController();

  const APPS_TABS: TabDefinition[] = useMemo(() => {
    if (!allReferencesSummary.app_references_count) {
      return [];
    }

    return allReferencesSummary.app_references_count.map(
      (app_count, index) => ({
        key: app_count.appName,
        label: '',
        icon: (
          <div
            className="flex gap-1.5 items-center"
            onClick={() => {
              trackEvent(AnalyticsEvent.QAReferencesSidecarNavigateTab, {
                question_id: questionId,
                position: index + 1,
                app_name: app_count.appName,
              });
            }}
          >
            <ReferenceIcon source={app_count.appName} />
            <div className="flex-shrink-0 bg-gray-20 w-7 h-4 flex items-center justify-center rounded-full text-xs">
              {app_count.numDocs}
            </div>
          </div>
        ),
        content: (
          <AppTabContent app_count={app_count} question_id={questionId} />
        ),
      })
    );
  }, [allReferencesSummary.app_references_count, questionId]);

  const handleShowReferencesSidecar = useCallback(
    ({
      allReferencesSummary: referencesSummary,
      query_id,
    }: Partial<QAControllerEventArgs>) => {
      if (referencesSummary && query_id) {
        setAllReferencesSummary(referencesSummary);
        setQuestionId(query_id);
        setOpen(true);

        if (isSidebarOpen) {
          toggleSidebar(false);
          setSidebarToggled(true);
        }
      }
    },
    [setOpen, isSidebarOpen]
  );

  useEffect(() => {
    qaController.listenEvent(
      'showReferencesSidecar',
      handleShowReferencesSidecar
    );

    return () => {
      qaController.off('showReferencesSidecar', handleShowReferencesSidecar);
    };
  }, [qaController, handleShowReferencesSidecar]);

  if (!open) {
    return null;
  }

  return (
    <div className="fixed inset-0 z-50 bg-white sm:relative w-full sm:w-2/5 max-w-xl border-l border-solid border-cloud-15 flex flex-col">
      <div className="pt-5 sm:pt-3 pb-6 px-6 flex justify-between">
        <div className="flex gap-4 sm:gap-2">
          <UIIcon className="invisible sm:visible" name="files" size={24} />
          <div className="font-medium">Sources</div>
        </div>
        <UIIconButton
          name="cross"
          onClick={() => {
            trackEvent(AnalyticsEvent.QAReferencesSidecarClosed, {
              question_id: questionId,
            });

            setOpen(false);

            if (sidebarToggled) {
              toggleSidebar(true);
            }
          }}
        />
      </div>

      <UITabs
        className="overflow-y-scroll h-[85%]"
        firstLastTitleMargin={3}
        initialSelectedTabIndex={0}
        // To re-render UITabs component when sidecar is already open and user wants to see other answer's sidecar content
        key={questionId}
        lowTitleWidth
        tabs={APPS_TABS}
      />
    </div>
  );
};
