import React, { FC } from 'react';
import { AppName, getAppDisplayName } from '../../../apps/definition';
import { LinkFavicon } from '../../controls/LinkFavicon/LinkFavicon';
import { AppIcons, UIIcon } from '../../controls/ui/UIIcon/UIIcon';
import { AnswerIcon } from '../../controls/ui/icons/icons';

export const ReferenceIcon: FC<{
  source: string;
  url?: string;
  size?: number;
}> = ({ source, url, size = 20 }) => {
  let icon;

  if ((source === 'web' || source === 'website') && url) {
    icon = <LinkFavicon link={url} size={size} />;
  } else if (source === 'verified_answers') {
    icon = <AnswerIcon size={size} />;
  } else if (source === 'files') {
    icon = <UIIcon name="doc" size={size} />;
  } else if (source && source !== '') {
    icon = <UIIcon name={source as AppIcons} size={size} type="apps" />;
  } else if (url) {
    icon = <LinkFavicon link={url} size={size} />;
  } else {
    return null;
  }

  return icon;
};

export const referenceName = (
  source: AppName | 'files' | 'verified_answers'
): string => {
  let name;

  if (source === 'files') {
    name = 'Files';
  } else if (source === 'verified_answers') {
    name = 'Answers';
  } else {
    name = getAppDisplayName(source);
  }

  return name;
};
