import { WorkflowType } from '../../models/workflow';
import { GenericAPI } from './GenericAPI';

export class WorkflowApi extends GenericAPI {
  public constructor() {
    super('workflows');
  }
  public async refreshState<S extends object>(
    stateToUpdate: Partial<S>,
    workflow_type: WorkflowType
  ): Promise<WorkflowApiResponse<S>> {
    return this.invoke({
      path: workflow_type,
      method: 'PATCH',
      data: stateToUpdate,
    });
  }

  public async markAsDone(workflow_type: WorkflowType): Promise<void> {
    return this.invoke({
      path: 'markAsDone',
      method: 'POST',
      data: { workflow_type },
    });
  }

  public async getStripeCustomerPortalLink(
    return_url: string
  ): Promise<CustomerPortalLinkResponse> {
    return this.invoke({
      path: 'getStripeCustomerPortalLink',
      method: 'POST',
      data: { return_url },
    });
  }
}

interface WorkflowApiResponse<S extends object> {
  state: S;
}

interface CustomerPortalLinkResponse {
  url: string;
}
