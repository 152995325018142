import { makeLazyComponent } from '../../../scripts/utils';

export const LazyBillingPortalPage = makeLazyComponent(
  async () =>
    (
      await import(
        /* webpackChunkName: "billing-portal-page" */ './BillingPortalPage'
      )
    ).BillingPortalPage
);
