import './Crash.scss';
// eslint-disable-next-line import/no-unresolved
import { FallbackRender } from '@sentry/react/types/errorboundary';
import React from 'react';
import { isWebDev } from '../../../scripts/utils';
import { LeenaChatBotBubble } from '../../controls/LeenaChatBotScript/LeenaChatBotScript';
import { UIButton } from '../../controls/ui/UIButton/UIButton';
import { UIIcon } from '../../controls/ui/UIIcon/UIIcon';

export const Crash: FallbackRender = ({
  resetError,
  eventId,
  error,
  componentStack,
}) => {
  const url = new URL(window.location.href);
  const queryParams = new URLSearchParams(url.search);
  const crashCountParam = queryParams.get('crashCount');
  const isStringAndParsable =
    typeof crashCountParam === 'string' &&
    !Number.isNaN(Number(crashCountParam));

  let crashCount = 0;

  if (isStringAndParsable) {
    crashCount = Number(crashCountParam);
  }

  crashCount++;
  queryParams.set('crashCount', crashCount.toString());

  const newURL =
    crashCount > 1
      ? new URL(`${url.origin}/?${queryParams.toString()}`)
      : new URL(`${url.origin}${url.pathname}?${queryParams.toString()}`);

  return (
    <>
      <div className="errorDisplay">
        <UIIcon className="icon" name="sad-face" size={200} />
        <h2 onClick={resetError}>Something went wrong.</h2>
        <span>And we are already on it!</span>
        <span style={{ color: 'grey', fontSize: '10px' }}>
          Trace id: {eventId}
        </span>
        <pre
          style={{ display: isWebDev() ? 'block' : 'none', textAlign: 'left' }}
        >
          {error.stack}
          {componentStack}
        </pre>
        <br />

        <UIButton href={newURL.href} noBlank>
          {crashCount > 1 ? 'Go to homepage' : 'Try again'}
        </UIButton>
      </div>
      <LeenaChatBotBubble />
    </>
  );
};
