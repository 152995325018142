import classNames from 'classnames';
import React, { useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { SidebarRoutes } from '../../containers/SidebarContainer';
import { trackEvent } from '../../extra/sharedMethods';
import { Workflow } from '../../models/Workflows';
import { invokeFastApi } from '../../scripts/apis/fastapi';
import { AnalyticsEvent } from '../../scripts/constants/analytics-event';
import { useUserSafe } from '../../scripts/hooks';
import { setFiltersFromWorkflowSources } from '../../scripts/sources/workflow';
import { logError } from '../../scripts/utils';
import { UIIcon } from '../controls/ui/UIIcon/UIIcon';
import { useQATextInputBoxStates } from '../jit-qa/textInputBox/QATextInputBoxStates';

interface IRecommendedWorkflows {
  recommendedWorkflows: Workflow[];
}

export const RecommendedWorkflows: React.FC<IRecommendedWorkflows> = ({
  recommendedWorkflows,
}) => {
  const user = useUserSafe();
  const history = useHistory();
  const {
    setSearchQuery,
    setSkillFilters,
    setFileFilters,
    setAnswerFilters,
    setIsBaseLLM,
    setSelectedLlm,
    allSkills,
    allFiles,
    allAnswers,
  } = useQATextInputBoxStates();

  const handleWorkflowClick = useCallback(
    (workflow: Workflow) => {
      try {
        invokeFastApi({
          path: `/prompts/prompt_used/${workflow.id}`,
          method: 'POST',
        });

        trackEvent(AnalyticsEvent.WorkflowUsage, { promptId: workflow.id });
      } catch (error) {
        logError(error);
      }

      setSearchQuery(workflow.template);
      setSelectedLlm(workflow.preferred_llm);
      setFiltersFromWorkflowSources(
        setIsBaseLLM,
        setSkillFilters,
        setFileFilters,
        setAnswerFilters,
        workflow,
        user.email,
        allSkills,
        allFiles,
        allAnswers
      );

      history.push(SidebarRoutes.JitQA);
    },
    [
      allAnswers,
      allFiles,
      allSkills,
      history,
      setAnswerFilters,
      setFileFilters,
      setIsBaseLLM,
      setSearchQuery,
      setSelectedLlm,
      setSkillFilters,
      user.email,
    ]
  );

  return (
    <div
      className={classNames('group relative flex items-center h-[38px]', {
        invisible: recommendedWorkflows.length === 0,
      })}
    >
      <div
        className="sm:flex hidden opacity-0 group-hover:opacity-40 absolute top-3 -left-5 bg-gray-20 rounded border-0 cursor-pointer"
        onClick={() => {
          document
            .getElementById('scrollContainer')
            ?.scrollBy({ left: -150, behavior: 'smooth' });
        }}
      >
        <UIIcon name="arrow-left" />
      </div>
      <div
        className="flex items-center gap-2 overflow-x-auto whitespace-nowrap no-scrollbar max-w-[750px]"
        id="scrollContainer"
      >
        {recommendedWorkflows.slice(0, 8).map((workflow) => (
          <div
            // eslint-disable-next-line max-len
            className="flex justify-between items-center py-1.5 px-3 min-w-[245px] neumorphic-background rounded cursor-pointer hover:bg-none hover:bg-purple-10 hover:border-purple-30 border border-solid border-gray-30 text-sm"
            key={workflow.id}
            onClick={() => {
              handleWorkflowClick(workflow);
            }}
          >
            <div className="max-w-[180px] whitespace-nowrap overflow-hidden truncate">
              {workflow.title}
            </div>
            <UIIcon name="arrow-up-right" stroke />
          </div>
        ))}
      </div>
      <div
        className="sm:flex hidden opacity-0 group-hover:opacity-40 absolute top-3 -right-1 bg-gray-20 rounded border-0 cursor-pointer"
        onClick={() => {
          document
            .getElementById('scrollContainer')
            ?.scrollBy({ left: 150, behavior: 'smooth' });
        }}
      >
        <UIIcon name="arrow-right" />
      </div>
    </div>
  );
};
