import { OrgByOrg, OrgPreferences, User } from '../../models/User';
import { WorkflowType } from '../../models/workflow';
import { PageSearchState } from '../../redux/pageSearch/state';
import { GlobalState } from '../../redux/state';
import { useSelector } from '../../redux/store';
import { WorkflowStoreInner } from '../../redux/workflow/workflowState';

const defaultUserSubSelector = (usr: User) => usr;

/**
 * Redux wrapper to supplies a component with the current user or a user property when using the `subSelector` function.
 *
 *
 * WARNING: Prefer using `userUserSafe` for all components that expect the user to be logged.
 */
export function useUser(): User | undefined {
  return useGlobalState((gs) => gs.user);
}

/**
 * Redux wrapper to supplies a component with the current user or a user property when using the `subSelector` function.
 * This function will throw when no user is set, it should only be used in components that expect a logged in user!
 *
 * @param [subSelector] Optional filter function that can be supplied to pick specific user attributes to reduce re-renders even further.
 */
export function useUserSafe<T>(subSelector: (user: User) => T): T;
export function useUserSafe(): User;
export function useUserSafe<T = User>(
  subSelector?: (user: User) => T
): T | User {
  // ASSERTION: If no subSelector is specified the generic can be ignored
  const actualSubSelector = subSelector ?? defaultUserSubSelector;
  return useSelector(({ user }: GlobalState) => {
    if (!user) {
      throw new Error('user not used');
    }

    return actualSubSelector(user);
  });
}

export const useUserId = (): string => useUserSafe((u) => u.userId);

/**
 * Utility to access page search.
 */
export function usePageSearch<T>(
  subSelector: (pageSearch: PageSearchState) => T
): T {
  return useSelector(({ pageSearch }: GlobalState) => subSelector(pageSearch));
}

/**
 * Utility to check if the aws token has been loaded.
 */
export function useAWSToken(): boolean {
  return useSelector(({ tokens }: GlobalState) => !!tokens?.awsTokens);
}

/**
 * Utility to access login token.
 */
export function useLoginTokens(): string | undefined {
  return useSelector(
    ({ tokens }: GlobalState) => tokens?.loginTokens?.id_token
  );
}

/**
 * Utility to access whether sidebar is open.
 */
export function useSidebarOpen(): boolean {
  return useGlobalState((state) => state.sidebar.open);
}

/**
 * Utility to provide type safety for global state access
 */
export function useGlobalState<T>(
  fn: (state: GlobalState) => T,
  equalityFn?: (a: T, B: T) => boolean
): T {
  return useSelector(fn, equalityFn);
}

/**
 * Utility to provide type safety for global state access
 */
export function useWorkflowState<T, K extends WorkflowType>(
  flowType: K,
  fn: (state: WorkflowStoreInner[K]) => T,
  equalityFn?: (a: T, B: T) => boolean
): T {
  return useSelector((s) => fn(s.workflows.data[flowType]), equalityFn);
}

export function useOrgPreferences<T>(selector: (pref: OrgPreferences) => T): T {
  return useUserSafe((user) => selector(user.orgByOrgId.preferences));
}

export function useOrgPreference<K extends keyof OrgPreferences>(
  pref: K,
  _default: OrgPreferences[K]
): OrgPreferences[K] {
  return useUserSafe((user) => user.orgByOrgId.preferences[pref] ?? _default);
}

export function useBackground(
  forceOrg?: boolean
): [isImage: boolean, color: string, image?: string] {
  const useUserImage = useOrgPreferences(
    (org) => org.background_allow_user && !forceOrg
  );

  return [
    useUserSafe(
      (user) =>
        ((useUserImage && user.preferences?.background?.type) ||
          user.orgByOrgId.preferences.background_type) === 'image'
    ),
    useUserSafe(
      (user) =>
        (useUserImage && user.preferences?.background?.color) ||
        user.orgByOrgId.preferences.background_color ||
        'ffffff'
    ),
    useUserSafe(
      (user) =>
        (useUserImage && user.preferences?.background?.image) ||
        user.orgByOrgId.preferences.background_image
    ),
  ];
}

export function useIsAdmin(): boolean {
  return useUserSafe((u) => u.admin);
}

export function useOrg(): OrgByOrg {
  return useUserSafe((user) => user.orgByOrgId);
}

export function useRecommendedQueries(): string[] | undefined {
  return useSelector<string[] | undefined>((s) => s.recommendedQueries);
}
