import './MembersPage.scss';
import React, { FC, useCallback } from 'react';
import { MembersTable } from '../../../components/admin/MembersTable/MembersTable';
import { UIIcon } from '../../../components/controls/ui/UIIcon/UIIcon';
import {
  TabDefinition,
  UITabs,
} from '../../../components/controls/ui/UITabs/UITabs';
import { UITextbox } from '../../../components/controls/ui/UITextbox/UITextbox';
import { useAdminGuard } from '../../../scripts/hooks';
import { MemberStatus } from '../../../scripts/models/org-member';
import { capitalize } from '../../../scripts/utils';

enum MemberPageTabType {
  INVITE = 'INVITE',
  ALL_USERS = 'ALL_USERS',
  SIGNED_UP = 'SIGNED_UP',
  INVITED = 'INVITED',
  BLOCKED = 'BLOCKED',
}

const MEMBER_TABS: TabDefinition[] = Object.keys(MemberPageTabType).map(
  (key: string) => ({
    label: capitalize(key.replace('_', ' ')),
    key,
  })
);

export const MembersPage: FC = () => {
  useAdminGuard();
  const getTabContent = useCallback((index: number) => {
    return (
      <MemberPageTab
        tabType={
          MemberPageTabType[MEMBER_TABS[index]!.key as MemberPageTabType]
        }
      />
    );
  }, []);

  const linkToShare = window.location.origin;

  return (
    <div className="mx-2 lg:mx-auto border-transparent my-24 max-w-5xl membersPage">
      <div className="flex gap-2 items-center">
        <UIIcon name="people" size={32} />
        <h2 className="text-2xl m-0 font-bold">Members</h2>
      </div>
      <div className="flex p-6 gap-4 rounded-lg bg-cloud-15 justify-between items-center mt-9 max-sm:p-4">
        <div>Manage your team and invite new members to try Dashworks.</div>
      </div>

      <div className="mt-10">
        Signup Link
        <div className="text-sm text-cloud-30">
          Anyone with this link will be able to join your team.
        </div>
        <UITextbox
          className="mt-2.5"
          plain
          readonly
          useCopy
          value={linkToShare}
        />
      </div>

      <UITabs
        className="mt-16 memberTabs"
        getTabContent={getTabContent}
        initialSelectedTabIndex={1}
        tabs={MEMBER_TABS}
      />
    </div>
  );
};

const MemberPageTab: FC<{ tabType: MemberPageTabType }> = ({ tabType }) => {
  let status: MemberStatus | undefined;
  switch (tabType) {
    case MemberPageTabType.INVITE: {
      status = MemberStatus.CREATED;
      break;
    }

    case MemberPageTabType.ALL_USERS: {
      status = undefined;
      break;
    }

    case MemberPageTabType.SIGNED_UP: {
      status = MemberStatus.SIGNED_UP;
      break;
    }

    case MemberPageTabType.INVITED: {
      status = MemberStatus.INVITED;
      break;
    }

    case MemberPageTabType.BLOCKED: {
      status = MemberStatus.BLOCKED;
      break;
    }
  }

  return <MembersTable filterStatus={status} />;
};
