import classNames from 'classnames';
import React, { FC, useCallback } from 'react';
import { trackEvent } from '../../../../extra/sharedMethods';
import { Reference } from '../../../../models/QAmodels';
import { useQAController } from '../../../../scripts/QAController';
import { AnalyticsEvent } from '../../../../scripts/constants/analytics-event';
import { ReferenceIcon } from '../QAReferenceUtil';

export const NavigationalItem: FC<{ item: Reference; index: number }> = ({
  item: reference,
  index,
}) => {
  const qaController = useQAController();
  const url = reference.url ?? '';
  const title = reference.title || url;
  const { source } = reference;

  const onReferenceClick = useCallback(
    (e: React.MouseEvent<HTMLDivElement>) => {
      trackEvent(
        AnalyticsEvent.QAMessageReferenceClicked,
        {
          position: index + 1,
        },
        {
          url,
          title,
          source,
        }
      );

      if (source === 'verified_answers') {
        // answer id is expected to be last fragment in url
        const answerId = url.split('/').pop();
        qaController.triggerEvent('showAnswerDialog', { answerId });
        e.preventDefault();
        return false;
      }
    },
    [url, title, source, index, qaController]
  );

  return (
    <div
      className={classNames('referencesItem resultItem', {
        previewActive: false,
      })}
      onClick={onReferenceClick}
      tabIndex={0}
    >
      <div className="activeContainer">
        <div className="resultLeft">
          <div>
            <ReferenceIcon source={source} url={url} />
          </div>
        </div>
        <div className="resultRight">
          <div className="titleContainer">
            <a href={url} rel="noreferrer" target="_blank">
              {title}
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};
