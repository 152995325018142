import React, { FC, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { SidebarRoutes } from '../../containers/SidebarContainer';
import { trackEvent } from '../../extra/sharedMethods';
import { invokeFastApi } from '../../scripts/apis/fastapi';
import { AnalyticsEvent } from '../../scripts/constants/analytics-event';
import { useUserSafe } from '../../scripts/hooks';
import { useWorkflowById } from '../../scripts/hooks/workflows';
import {
  checkAiOnlyModeFromSources,
  checkWebModeFromSources,
} from '../../scripts/sources/common';
import {
  getFiltersFromWorkflowSources,
  setFiltersFromWorkflowSources,
} from '../../scripts/sources/workflow';
import { logError } from '../../scripts/utils';
import { llms } from '../LLMSelect/LLMSelectList';
import { Loading } from '../controls/Loading/Loading';
import { UIButton } from '../controls/ui/UIButton/UIButton';
import { UIIcon } from '../controls/ui/UIIcon/UIIcon';
import { useQATextInputBoxStates } from '../jit-qa/textInputBox/QATextInputBoxStates';
import { IInnerComponent } from './QAWorkflowTemplateModal';

export const ViewWorkflowTemplate: FC<IInnerComponent> = ({
  onClose,
  workflowId,
}: IInnerComponent) => {
  const user = useUserSafe();
  const { loading, workflow } = useWorkflowById(workflowId);
  const history = useHistory();
  const {
    setSearchQuery,
    setSkillFilters,
    setFileFilters,
    setAnswerFilters,
    setIsBaseLLM,
    setSelectedLlm,
    allSkills,
    allFiles,
    allAnswers,
  } = useQATextInputBoxStates();

  const handleUseButtonClick = useCallback(() => {
    if (!workflow) return;

    try {
      invokeFastApi({
        path: `/prompts/prompt_used/${workflow.id}`,
        method: 'POST',
      });

      trackEvent(AnalyticsEvent.WorkflowUsage, { promptId: workflow.id });
    } catch (fetchError) {
      logError(fetchError);
    }

    setSearchQuery(workflow.template);
    setSelectedLlm(workflow.preferred_llm);
    setFiltersFromWorkflowSources(
      setIsBaseLLM,
      setSkillFilters,
      setFileFilters,
      setAnswerFilters,
      workflow,
      user.email,
      allSkills,
      allFiles,
      allAnswers
    );

    history.push(SidebarRoutes.JitQA);
  }, [
    allAnswers,
    allFiles,
    allSkills,
    history,
    setAnswerFilters,
    setFileFilters,
    setIsBaseLLM,
    setSearchQuery,
    setSelectedLlm,
    setSkillFilters,
    user.email,
    workflow,
  ]);

  if (loading)
    return (
      <div className="mt-40">
        <Loading />
      </div>
    );

  if (!workflow) return null;

  return (
    <div className="relative h-full">
      <div className="flex flex-col gap-6 max-h-[420px] overflow-y-auto pb-16">
        <div>
          <div className="font-bold">Title</div>
          <div className="font-normal">{workflow.title}</div>
        </div>

        {workflow.description && (
          <div>
            <div className="font-bold">Description</div>
            <div className="font-normal">{workflow.description}</div>
          </div>
        )}

        <div>
          <div className="font-bold">Preview</div>
          <div className="font-normal">{workflow.template}</div>
        </div>

        <div>
          <div className="font-bold">Sources</div>
          {checkAiOnlyModeFromSources(workflow.sources) ? (
            <div className="font-normal">AI only</div>
          ) : // eslint-disable-next-line unicorn/no-nested-ternary
          checkWebModeFromSources(workflow.sources) ? (
            <div className="font-normal">Web</div>
          ) : (
            <div className="flex items-center gap-2 flex-wrap">
              {[
                ...new Set(
                  getFiltersFromWorkflowSources(
                    workflow,
                    user.email,
                    allSkills,
                    allFiles,
                    allAnswers
                  )[0].map((skill) => skill.appName)
                ),
              ].map((app) => (
                <div key={app}>
                  <UIIcon name={app} type="apps" />
                </div>
              ))}
              {getFiltersFromWorkflowSources(
                workflow,
                user.email,
                allSkills,
                allFiles,
                allAnswers
              )[1].length > 0 && (
                <div>
                  <UIIcon name="doc" size={20} />
                </div>
              )}
              {getFiltersFromWorkflowSources(
                workflow,
                user.email,
                allSkills,
                allFiles,
                allAnswers
              )[2].length > 0 && (
                <div>
                  <UIIcon name="answer" size={20} />
                </div>
              )}
            </div>
          )}
        </div>

        {workflow.preferred_llm && (
          <div>
            <div className="font-bold">AI Model</div>
            <div className="font-normal flex gap-2 items-center">
              {llms.find((llm) => llm.value === workflow.preferred_llm)?.icon}
              {
                llms.find((llm) => llm.value === workflow.preferred_llm)
                  ?.displayName
              }
            </div>
          </div>
        )}

        <div>
          <div className="font-bold">Created By</div>
          <div className="font-normal">{workflow.author.display_name}</div>
        </div>
      </div>

      <div className="fixed bottom-0 left-0 right-0 flex justify-between p-4">
        <UIButton onClick={onClose} type="secondary">
          Cancel
        </UIButton>
        <UIButton onClick={handleUseButtonClick}>Use</UIButton>
      </div>
    </div>
  );
};
