export enum MessageAction {
  OpenExtensionNewTab = 'dash-open-extension-new-tab',
  RefreshNotionCookie = 'dash-refresh-notion-cookie',
  SetTrackingSettings = 'dash-set-tracking-settings',
  SetUseNewTab = 'dash-set-use-new-tab',
  SearchHistoryRequest = 'dash-search-history-request',
  SearchHistoryResponse = 'dash-search-history-response',
  SetCredentials = 'dash-set-credentials',
  UserLogout = 'dash-user-logout',
  ExtensionSettingsPageNavigate = 'dash-extension-settings-page-navigate',
}
export enum EventSource {
  CONTENT_SCRIPT = 'content_script',
  WEB_APP = 'web_app',
  EXTENSION_WORKER = 'extension_worker',
}
