import {
  SubscriptionPlanType,
  SubscriptionStatus,
  SubscriptionSummary,
} from '../models/User';
import { WorkflowType } from '../models/workflow';
import { workflowAPI } from '../scripts/apis';
import { useFlag, useUserSafe } from '../scripts/hooks';
import { logError } from '../scripts/utils';

export function hasSubscriptionTrialEnded(
  subscriptionSummary: SubscriptionSummary
): boolean {
  const now_ts = Date.now();
  return (
    subscriptionSummary.status === SubscriptionStatus.TRIAL &&
    subscriptionSummary.trialEndAt !== null &&
    subscriptionSummary.trialEndAt !== undefined &&
    now_ts - new Date(subscriptionSummary.trialEndAt).getTime() > 0
  );
}

export function useSubscriptionSummary(): SubscriptionSummary | undefined {
  return useUserSafe((user) =>
    user.orgByOrgId.dashSubscriptionsByOrgDomain.nodes.find(
      (s) => s.status !== SubscriptionStatus.CANCELLED
    )
  );
}

export function useHasValidSubscriptionPlan(): boolean {
  const subscription = useSubscriptionSummary();
  const hasInvalidSub =
    !subscription ||
    subscription.status === SubscriptionStatus.INACTIVE ||
    hasSubscriptionTrialEnded(subscription);

  return !hasInvalidSub;
}

export const useIsWorkspaceSetupNeeded = (): boolean => {
  const workspaceSetupDisabled = useFlag('workspaceSetupDisabled');
  const isWorkspaceSetup = useUserSafe(
    (user) => user.orgByOrgId.isWorkspaceSetup
  );

  if (workspaceSetupDisabled) {
    if (!isWorkspaceSetup) {
      workflowAPI.markAsDone(WorkflowType.WORKSPACE_ONBOARDING).catch(logError);
    }

    return false;
  }

  return !isWorkspaceSetup;
};

export const useHasEnterpriseFeatures = (): boolean => {
  const subscription = useSubscriptionSummary();
  return (
    (subscription?.status === SubscriptionStatus.ACTIVE &&
      subscription.planType === SubscriptionPlanType.ENTERPRISE) ||
    (subscription?.status === SubscriptionStatus.TRIAL &&
      !hasSubscriptionTrialEnded(subscription) &&
      subscription.planType === SubscriptionPlanType.ENTERPRISE)
  );
};
