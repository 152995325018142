import React, { useCallback, useState } from 'react';
import { trackEvent } from '../../../extra/sharedMethods';
import { batch } from '../../../redux/store';
import { MemberActionArgs, takeMemberAction } from '../../../scripts/apis';
import { AnalyticsEvent } from '../../../scripts/constants/analytics-event';
import { useBoolState, useToaster, useUserSafe } from '../../../scripts/hooks';
import { MemberStatus } from '../../../scripts/models/org-member';
import { logError } from '../../../scripts/utils';
import {
  ConfirmDialog,
  ConfirmMode,
} from '../../controls/Dialog/ConfirmDialog';
import { UIButton } from '../../controls/ui/UIButton/UIButton';

export enum MemberActions {
  BLOCK = 'block',
  UNBLOCK = 'unblock',
  RESEND_INVITE = 'resend_invite',
  SEND_INVITE = 'send_invite',
  CHANGE_ROLE = 'change_role',
}

export const handleMemberAction = async (
  action: string,
  params: MemberActionArgs
): Promise<void> => {
  switch (action) {
    case MemberActions.BLOCK:
      trackEvent(AnalyticsEvent.MemberBlock);
      break;
    case MemberActions.UNBLOCK:
      trackEvent(AnalyticsEvent.MemberUnblock);
      break;
    case MemberActions.RESEND_INVITE:
      trackEvent(AnalyticsEvent.MemberResendInvite);
      break;
    case MemberActions.SEND_INVITE:
      trackEvent(AnalyticsEvent.MemberSendInvite);
      break;
    case MemberActions.CHANGE_ROLE:
      trackEvent(AnalyticsEvent.MemberChangeRole);
      break;
  }

  await takeMemberAction(action, params);
};

export const MemberActionButtonsRow: React.FC<{
  status: MemberStatus;
  emails: string[];
}> = ({ status, emails }) => {
  const authenticatedUser = useUserSafe();

  const [successMessage, setSuccessMessage] = useState<string | undefined>();
  const [isProcessing, setProcessing, setNotProcessing] = useBoolState(false);
  const [isConfirmModalShown, showConfirmModal, hideConfirmModal] =
    useBoolState(false);

  const toaster = useToaster();

  const handleAction = useCallback(
    (action: string, _successMessage: string) => {
      const body = { emails };
      setProcessing();
      handleMemberAction(action, body)
        .then(() => {
          batch(() => {
            setNotProcessing();
            setSuccessMessage(_successMessage);
          });
        })
        .catch((error) => {
          logError(error);
          toaster.failure();
          setNotProcessing();
        });
    },
    [emails, setNotProcessing, setProcessing, toaster]
  );

  const handleBlock = useCallback(
    (_e, _confirmed = false) => {
      if (_confirmed) {
        handleAction(MemberActions.BLOCK, 'Blocked successfully!');
      } else {
        showConfirmModal();
      }
    },
    [handleAction, showConfirmModal]
  );

  const handleInvite = useCallback(() => {
    handleAction(MemberActions.SEND_INVITE, 'Invite sent successfully!');
  }, [handleAction]);

  const handleResendInvite = useCallback(() => {
    handleAction(MemberActions.RESEND_INVITE, 'Resent invite successfully!');
  }, [handleAction]);

  const handleUnblock = useCallback(() => {
    handleAction(MemberActions.UNBLOCK, 'Unblocked successfully!');
  }, [handleAction]);

  const handleBlockConfirmed = useCallback(() => {
    handleBlock(null, true);
  }, [handleBlock]);

  if (successMessage) {
    return <div className="actionSuccessText">{successMessage}</div>;
  }

  switch (status) {
    case MemberStatus.SIGNED_UP: {
      return authenticatedUser.admin ? (
        <>
          <UIButton
            onClick={handleBlock}
            processing={isProcessing}
            type="secondary"
          >
            Block
          </UIButton>
          {isConfirmModalShown && (
            <ConfirmDialog
              mode={ConfirmMode.Confirm}
              onCancel={hideConfirmModal}
              onConfirm={handleBlockConfirmed}
              subTitle="Are you sure you want to block this user?"
              subject="Block"
            />
          )}
        </>
      ) : null;
    }

    case MemberStatus.BLOCKED: {
      return authenticatedUser.admin ? (
        <UIButton
          onClick={handleUnblock}
          processing={isProcessing}
          type="secondary"
        >
          Unblock
        </UIButton>
      ) : null;
    }

    case MemberStatus.DELETED: {
      return null;
    }

    case MemberStatus.INVITED: {
      return (
        <UIButton
          onClick={handleResendInvite}
          processing={isProcessing}
          type="secondary"
        >
          Resend Invite
        </UIButton>
      );
    }

    case MemberStatus.CREATED: {
      return (
        <UIButton
          onClick={handleInvite}
          processing={isProcessing}
          type="secondary"
        >
          Invite
        </UIButton>
      );
    }

    default: {
      return null;
    }
  }
};
