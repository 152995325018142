import { Action } from '../actions';

export enum AdminAction {
  SET_ORG_USERS = 'ADMIN_SET_ORG_USERS',
  UPDATE_ORG_USER_ROLE = 'ADMIN_UPDATE_ORG_USER_ROLE',
  SET_SIDEBAR_OPEN = 'ADMIN_SET_SIDEBAR_OPEN',
  SET_SIDEBAR_KNOWLEDGE_OPEN = 'ADMIN_SET_SIDEBAR_KNOWLEDGE_OPEN',
  SET_SIDEBAR_ADMIN_OPEN = 'ADMIN_SET_SIDEBAR_ADMIN_OPEN',
  SET_SIDEBAR_PERSONAL_OPEN = 'ADMIN_SET_SIDEBAR_PERSONAL_OPEN',
  SET_SIDEBAR_SETTINGS_OPEN = 'ADMIN_SET_SIDEBAR_SETTINGS_OPEN',
}

export function setSidebarOpen(payload: boolean): Action {
  return {
    type: AdminAction.SET_SIDEBAR_OPEN,
    payload,
  };
}

export function setSidebarKnowledgeOpen(payload: boolean): Action {
  return {
    type: AdminAction.SET_SIDEBAR_KNOWLEDGE_OPEN,
    payload,
  };
}

export function setSidebarAdminOpen(payload: boolean): Action {
  return {
    type: AdminAction.SET_SIDEBAR_ADMIN_OPEN,
    payload,
  };
}

export function setSidebarPersonalOpen(payload: boolean): Action {
  return {
    type: AdminAction.SET_SIDEBAR_PERSONAL_OPEN,
    payload,
  };
}

export function setSidebarSettingsOpen(payload: boolean): Action {
  return {
    type: AdminAction.SET_SIDEBAR_SETTINGS_OPEN,
    payload,
  };
}
