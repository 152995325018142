/* eslint-disable @cspell/spellchecker */
import './MarkdownParser.scss';
import React, { FC } from 'react';
import ReactMarkdown from 'react-markdown';
import { CodeProps } from 'react-markdown/lib/ast-to-react';
import { ReactMarkdownProps } from 'react-markdown/lib/complex-types';
import SyntaxHighlighter from 'react-syntax-highlighter';
import { stackoverflowDark } from 'react-syntax-highlighter/dist/esm/styles/hljs';
import rehypeRaw from 'rehype-raw';
import remarkGfm from 'remark-gfm';
import { stripReferences } from '../../scripts/utils';

export const MarkdownParser: FC<{
  text: string;
  className?: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  customAnchorElement?: React.ComponentType<any>;
  pluginsDisabled?: boolean;
}> = ({ text, className, customAnchorElement, pluginsDisabled = false }) => {
  return (
    <div className="markdownParser">
      <ReactMarkdown
        className={className}
        components={
          customAnchorElement
            ? { code: CodeBlock, table: Table, a: customAnchorElement }
            : { code: CodeBlock, table: Table }
        }
        linkTarget="_blank"
        rehypePlugins={pluginsDisabled ? [] : [rehypeRaw]}
        remarkPlugins={pluginsDisabled ? [] : [remarkGfm]}
      >
        {text}
      </ReactMarkdown>
    </div>
  );
};

const CodeBlock: FC<CodeProps> = ({
  inline,
  className,
  children,
  ...props
}) => {
  const match = /language-(\w+)/.exec(className ?? '');
  const language = match?.[1] ?? '';

  return inline ? (
    <code {...props} className={className}>
      {children}
    </code>
  ) : (
    <SyntaxHighlighter
      {...props}
      CodeTag="div"
      PreTag="div"
      codeTagProps={{ className: 'codeBlock' }}
      customStyle={{
        padding: '8px 16px',
        borderRadius: '8px',
      }}
      language={language}
      style={stackoverflowDark}
      wrapLongLines
    >
      {stripReferences(String(children))}
    </SyntaxHighlighter>
  );
};

const Table: FC<ReactMarkdownProps> = ({ children }) => {
  return (
    <div className="markdownTable">
      <table>{children}</table>
    </div>
  );
};
