import './index.scss';
import './main.css';
import { BrowserTracing } from '@sentry/browser';
import { ExtraErrorData } from '@sentry/integrations';
import * as Sentry from '@sentry/react';
import { ErrorBoundary } from '@sentry/react';
import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { QueryParamProvider } from 'use-query-params';
import { ReactRouter5Adapter } from 'use-query-params/adapters/react-router-5';
import { LoginRouter } from './LoginRouter';
import { EnvIndicator } from './components/dev/EnvIndicator';
import { Crash } from './components/errors/Crash/Crash';
import { trackEvent } from './extra/sharedMethods';
import { ElementIds } from './ids';
import { AuthWindow } from './pages/auth/LoginPage/AuthWindow';
import { Provider } from './redux/store';
import { LOGIN_WINDOW_NAME } from './scripts/authentication';
import { AnalyticsEvent } from './scripts/constants/analytics-event';
import { getSidebarOpenFromStorage } from './scripts/sidebar';
import { inExtension, isWebDev, isWebStaging } from './scripts/utils';
import { isExtensionInstalled } from './scripts/web-extension';

if (!isWebDev()) {
  const environment = isWebStaging() ? 'staging' : 'prod';

  Sentry.init({
    dsn: SENTRY_DSN,
    release: APP_VERSION,
    environment,
    debug: false,
    enabled: true,
    integrations: [
      new BrowserTracing({
        // Tracking performance of search and autosuggestion requests
        tracingOrigins: [new URL(SEARCH_URL).hostname],
        routingInstrumentation: Sentry.reactRouterV5Instrumentation(history),
      }),
      new ExtraErrorData({
        depth: 5,
      }),
    ],
    beforeSend(event) {
      // If the exception message contains "network error"
      if (
        event.exception?.values?.some((value) =>
          /network error/i.test(value.value ?? '')
        ) &&
        Math.random() > 0.05
      ) {
        return null;
      }

      return event;
    },
    ignoreErrors: [/ResizeObserver loop limit exceeded/],
    // Trace 80% of transactions
    tracesSampleRate: 0.8,
  });
}

window.addEventListener('focus', () => {
  trackEvent(AnalyticsEvent.Focus);
});

window.addEventListener('blur', () => {
  trackEvent(AnalyticsEvent.Blur);
});

window.addEventListener('load', async () => {
  trackEvent(AnalyticsEvent.Load, {
    extension_installed: await isExtensionInstalled(),
    sidebar_open: getSidebarOpenFromStorage(),
  });

  trackEvent(
    (await isExtensionInstalled())
      ? AnalyticsEvent.ExtensionInstalled
      : AnalyticsEvent.ExtensionNotInstalled
  );
});

document.addEventListener(
  'DOMContentLoaded',
  () => {
    // if we are opened in a window for login we load a minimal app that only listens to query params
    const core =
      window.opener && window.name === LOGIN_WINDOW_NAME ? (
        <BrowserRouter>
          <QueryParamProvider adapter={ReactRouter5Adapter}>
            <AuthWindow />
            <EnvIndicator />
          </QueryParamProvider>
        </BrowserRouter>
      ) : (
        <Provider>
          <LoginRouter />
        </Provider>
      );

    ReactDOM.render(
      <ErrorBoundary fallback={Crash}>{core}</ErrorBoundary>,
      document.getElementById(ElementIds.RootElement)
    );
  },
  {
    once: true,
  }
);

if (inExtension()) {
  /*
   * When embedded in an iframe `tab to focus search` does not work right
   * as for some reason our search input immediately loses focus and the window
   * is focused instead.
   * So if we are in an extension we immediately focus the search input when the window
   * is focused on load but only when no other element is focused.
   */
  window.addEventListener('focus', () => {
    const el = document.querySelector<HTMLInputElement>(
      '.topSearchInput input'
    );

    setTimeout(() => {
      if (document.activeElement !== document.body) {
        return;
      }

      el?.focus();
    }, 1);
  });
}

// Let extension know to always redirect to this URL
if (USE_CUSTOM_URL) {
  // TODO: Remove after next extension release
  document.body.dataset.onPremiseDeploymentUrl = APP_ORIGIN;
  document.body.dataset.customUrl = APP_ORIGIN;
}

// If app origin is not equal to current origin we are on a custom domain, but not on prem
if (APP_ORIGIN !== location.origin) {
  // TODO: Remove after next extension release
  document.body.dataset.onPremiseDeploymentUrl = location.origin;
  document.body.dataset.customUrl = location.origin;
}
