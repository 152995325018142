import { useCallback, useEffect } from 'react';
import { trackEvent } from '../extra/sharedMethods';
import { setTokens } from '../redux/actions';
import { setUserDisplayName, setUserPhotoUrl } from '../redux/meta/actions';
import { setSidebarOpen } from '../redux/sidebar/actions';
import { batch, store, useDispatch } from '../redux/store';
import {
  logoutUser,
  refreshLoginTokens,
  RefreshResultCode,
  SuccessfulResult,
} from './apis';
import { AnalyticsEvent } from './constants/analytics-event';
import { EventSource, MessageAction } from './constants/message-action';
import { AwsTokens } from './models/aws-tokens';
import { CommonTokens } from './models/login-tokens';
import { UserMetadata } from './models/user-metadata';
import { getSidebarOpenFromStorage } from './sidebar';
import {
  clearStorage,
  getStorageItem,
  setStorageItem,
  StorageKey,
} from './storage';
import {
  dedupePromise,
  deleteCookie,
  deploymentOrigin,
  logDebug,
  logError,
  openCenterPopup,
} from './utils';

class AuthError extends Error {
  public override readonly name = 'AuthError';
  public constructor() {
    super('Token refresh failed.');
  }
}

export const refreshAllTokens = dedupePromise(
  async (): Promise<[CommonTokens, AwsTokens]> => {
    const loginTokens = getLoginTokenFromLocalStorage();
    if (loginTokens === null) {
      throw new AuthError();
    }

    const refreshResult = await refreshLoginTokens(loginTokens);
    if (refreshResult.code === RefreshResultCode.Unauthenticated) {
      throw new AuthError();
    }

    if (refreshResult.code === RefreshResultCode.Error) {
      // on server error keep old tokens, only fail if we don't already have aws tokens
      const oldAwsTokens = getAwsTokensFromLocalStorage();
      if (!oldAwsTokens) {
        throw new AuthError();
      }

      return [loginTokens, oldAwsTokens];
    }

    const { commonTokens, awsTokens } = refreshResult as SuccessfulResult;

    storeLoginToken(commonTokens);
    storeAwsTokens(awsTokens);

    store.dispatch(
      setTokens({
        loginTokens,
        awsTokens,
      })
    );

    logDebug('sending credentials via postMessage to ', deploymentOrigin);
    window.postMessage(
      {
        action: MessageAction.SetCredentials,
        payload: { awsTokens, loginTokens },
        source: EventSource.WEB_APP,
      },
      deploymentOrigin
    );

    return [commonTokens, awsTokens];
  }
);

/**
 * Refreshes Google and AWS tokens with an exponential backoff mechanism for 500 errors.
 */
export const refreshAllTokensOrLogout = async (): Promise<
  [CommonTokens, AwsTokens]
> => {
  try {
    return await refreshAllTokens();
  } catch (error) {
    logError(error);
    return logout();
  }
};

export function getLoginTokenFromLocalStorage(): CommonTokens | null {
  return getStorageItem(StorageKey.LoginTokens) as CommonTokens | null;
}

function storeLoginToken(loginTokens: CommonTokens): void {
  setStorageItem(StorageKey.LoginTokens, loginTokens);
}

function getAwsTokensFromLocalStorage(): AwsTokens | null {
  return getStorageItem(StorageKey.AwsTokens) as AwsTokens | null;
}

function storeAwsTokens(awsTokens: AwsTokens) {
  setStorageItem(StorageKey.AwsTokens, awsTokens);
}

export async function softLogout(): Promise<void> {
  try {
    const tokens = getLoginTokenFromLocalStorage();
    if (!tokens || (await logoutUser(tokens))) {
      // eslint-disable-next-line no-console
      console.log('Logout succeeded');
    }
  } finally {
    clearStorage();
    // This may fail for iFramed page
    analytics.reset();
    window.postMessage(
      {
        action: MessageAction.UserLogout,
        payload: {},
        source: EventSource.WEB_APP,
      },
      deploymentOrigin
    );
  }
}

export async function logout(): Promise<never> {
  try {
    const tokens = getLoginTokenFromLocalStorage();
    if (!tokens || (await logoutUser(tokens))) {
      // eslint-disable-next-line no-console
      console.log('Logout succeeded');
    }
  } finally {
    deleteCookie('user_logged_in');

    clearStorage();

    window.postMessage(
      {
        action: MessageAction.UserLogout,
        payload: {},
        source: EventSource.WEB_APP,
      },
      deploymentOrigin
    );

    // This may fail for iFramed page
    analytics.reset();

    location.reload();
  }

  return new Promise(() => {
    // never resolves
  });
}

export const LOGIN_WINDOW_NAME = 'login';

export const openLoginPopup = (url: string): void => {
  trackEvent(AnalyticsEvent.LoginClick);
  openCenterPopup(url, LOGIN_WINDOW_NAME)!;
};

export const tryPopulateLocalStorageFromAuthRedirect = (): boolean => {
  const query = new URLSearchParams(location.search.slice(1));
  const expiresAt = Number.parseInt(query.get('expires_at') ?? '0', 10);
  const idToken = query.get('id_token');
  const refreshToken = query.get('refresh_token');
  const username = query.get('username');
  const displayName = query.get('display_name');
  const photoUrl = query.get('photo_url');

  if (!idToken) {
    return false;
  }

  if (!refreshToken || !username) {
    logError(new Error('Invalid data redirect auth query'));
    return false;
  }

  storeLoginToken({
    expires_at: expiresAt,
    id_token: idToken,
    refresh_token: refreshToken,
    username,
  });

  setStorageItem(StorageKey.UserMetadata, {
    photoUrl,
    displayName,
  });

  return true;
};

export const useBackgroundSyncAuthDataFromLocalStorage = (): void => {
  const dispatch = useDispatch();

  useEffect(() => {
    const storageHandler = (ev: StorageEvent) => {
      if (ev.storageArea !== localStorage) {
        return;
      }

      if (
        ev.key === StorageKey.AwsTokens ||
        ev.key === StorageKey.LoginTokens
      ) {
        if (!ev.newValue) {
          // not taking any chances.
          location.reload();
          return;
        }

        const loginTokens = getLoginTokenFromLocalStorage();
        const awsTokens = getAwsTokensFromLocalStorage();
        if (loginTokens && awsTokens) {
          dispatch(
            setTokens({
              loginTokens,
              awsTokens,
            })
          );
        }
      }

      if (ev.key === StorageKey.UserMetadata && ev.newValue) {
        const metadata = JSON.parse(ev.newValue) as UserMetadata;
        dispatch(setUserDisplayName(metadata.displayName));
        dispatch(setUserPhotoUrl(metadata.photoUrl));
      }
    };

    window.addEventListener('storage', storageHandler);

    return () => {
      window.removeEventListener('storage', storageHandler);
    };
  }, [dispatch]);
};

export const useSyncAuthDataFromLocalStorage = (): (() => void) => {
  const dispatch = useDispatch();

  return useCallback(() => {
    const loginTokens = getLoginTokenFromLocalStorage();
    const metadata = getStorageItem(
      StorageKey.UserMetadata
    ) as UserMetadata | null;

    batch(() => {
      if (loginTokens) {
        dispatch(
          setTokens({
            loginTokens,
          })
        );
      }

      if (metadata) {
        dispatch(setUserDisplayName(metadata.displayName));
        dispatch(setUserPhotoUrl(metadata.photoUrl));
      }

      dispatch(setSidebarOpen(getSidebarOpenFromStorage()));
    });
  }, [dispatch]);
};
