import { useMemo } from 'react';
import {
  AppDefinitions,
  AppName,
  InstantAvailableStatus,
} from '../../apps/definition';
import { isInstantAvailable } from '../../components/admin/AppsTable/AppUtils';
import { AppState } from '../../constants';
import { UserApp } from '../../models/User';
import { useAppConnectionStatus } from '../../pages/onboarding/WorkspaceSetupPage/hooks/app-connection-summary';

export interface ConnectedApps {
  id?: number;
  appName: AppName;
  displayName: string;
  connectionName?: string;
  workspaceName?: string;
  isOrg?: boolean;
}

interface SortedInstantApps {
  sortedInstantConnectedApps: ConnectedApps[];
}

export const useSortedInstantApps = (): SortedInstantApps => {
  const connectedApps = useAppConnectionStatus();

  const sortedInstantConnectedApps = useMemo(() => {
    const instantConnectedApps = connectedApps.reduce(
      (acc: ConnectedApps[], curr) => {
        if (
          curr.showInAppStore &&
          curr.isInstant &&
          curr.statusCode !== AppState.ErrorReconnect
        ) {
          acc.push({
            id: curr.id,
            appName: curr.appName,
            displayName: curr.displayName,
            connectionName: curr.connectionName,
            workspaceName: curr.workspaceName,
            isOrg: curr.isOrg,
          });
        } else if (
          curr.showInAppStore &&
          !curr.isInstant &&
          curr.statusCode === AppState.IndexingComplete
        ) {
          acc.push({
            id: curr.id,
            appName: curr.appName,
            displayName: curr.displayName,
            workspaceName: curr.workspaceName,
            isOrg: curr.isOrg,
          });
        }

        return acc;
      },
      []
    );

    return instantConnectedApps.sort((appA, appB) => {
      return appA.appName.localeCompare(appB.appName);
    });
  }, [connectedApps]);

  return {
    sortedInstantConnectedApps,
  };
};

export const filterSortedInstantApps = (
  apps: UserApp[],
  appDefinitions: AppDefinitions
): SortedInstantApps => {
  const instantConnectedApps = apps.reduce((acc: ConnectedApps[], curr) => {
    const definition = appDefinitions[curr.appName];

    if (!definition) {
      return acc;
    }

    const isOrg = curr.org !== '';
    const isInstant = isInstantAvailable(
      definition,
      isOrg ? InstantAvailableStatus.Org : InstantAvailableStatus.Personal
    );

    if (
      definition.showInAppStore &&
      isInstant &&
      curr.statusCode !== AppState.ErrorReconnect
    ) {
      acc.push({
        id: curr.id,
        appName: curr.appName,
        displayName: definition.displayName,
        connectionName: curr.connectionName,
        workspaceName: curr.workspaceName,
        isOrg,
      });
    } else if (
      definition.showInAppStore &&
      !isInstant &&
      curr.statusCode === AppState.IndexingComplete
    ) {
      acc.push({
        id: curr.id,
        appName: curr.appName,
        displayName: definition.displayName,
        workspaceName: curr.workspaceName,
        isOrg,
      });
    }

    return acc;
  }, []);

  return {
    sortedInstantConnectedApps: instantConnectedApps.sort((appA, appB) => {
      return appA.appName.localeCompare(appB.appName);
    }),
  };
};
