import React, { ReactNode, useMemo } from 'react';
import { UIIcon } from '../components/controls/ui/UIIcon/UIIcon';
import { ObjectType } from '../scripts/constants/filters';
import { FeatureFlags, useAllFlags } from '../scripts/hooks';

export interface AppDefinition {
  displayName: string;
  /** Whether we let users control this integration */
  controlEnabled?: boolean;
  shortname: AppName;
  /**
   * If specified, will be used when updating auth codes/oauth etc.
   */
  parent?: AppName;
  description: ReactNode | string;
  connectionType: AppConnectionType;
  objectTypes?: ObjectType[];
  icon?: ReactNode;
  instantAvailable?: InstantAvailableStatus;
  appCategory?: AppCategory[];
  popularAppSortOrder?: number;
  showInAppStore?: boolean;
}

export type AppDefinitions = {
  [key in AppName]?: AppDefinition;
};

/**
 * We use the following table as a reference for app connection states:
 * https://www.notion.so/dashworks/ddc1ab5d7de04c37a730a06d6af25965?v=df7a4f74f95a4d8da35afca89a977633
 */
export enum AppConnectionType {
  // First admin can connect org connection only once, no admin/member can disconnect afterwards
  OrgRestricted,

  // Only admins can take action on org connections
  OrgOnly,

  // Connecting org and personal apps result in different objects being indexed
  AccessControl,

  // Only members can take action on personal connections
  PersonalOnly,

  Other,
}

export enum InstantAvailableStatus {
  NotAvailable,
  Org,
  Personal,
  Both,
}

export enum AppCategory {
  POPULAR = 'POPULAR',
  ALL = 'ALL',
  WIKIS_DOCS = 'WIKIS_DOCS',
  MESSAGING = 'MESSAGING',
  SUPPORT = 'SUPPORT',
  CRM = 'CRM',
  PROJECT_MANAGEMENT = 'PROJECT_MANAGEMENT',
  INTRANET = 'INTRANET',
  WEBSITE = 'WEBSITE',
  HRIS = 'HRIS',
  CALENDAR = 'CALENDAR',
  ATS = 'ATS',
  ITSM = 'ITSM',
  CODE = 'CODE',
  ANALYTICS = 'ANALYTICS',
  MARKETING = 'MARKETING',
}

const defs: Readonly<AppDefinitions> = {
  aha: {
    objectTypes: [ObjectType.All, ObjectType.Tasks],
    connectionType: AppConnectionType.PersonalOnly,
    displayName: 'Aha!',
    controlEnabled: true,
    shortname: 'aha',
    description: 'Features, Ideas and Epics',
    instantAvailable: InstantAvailableStatus.Personal,
    appCategory: [AppCategory.ALL, AppCategory.PROJECT_MANAGEMENT],
    showInAppStore: true,
  },
  asana: {
    objectTypes: [ObjectType.All, ObjectType.Tasks],
    connectionType: AppConnectionType.PersonalOnly,
    displayName: 'Asana',
    controlEnabled: true,
    shortname: 'asana',
    description: 'Tasks',
    instantAvailable: InstantAvailableStatus.Personal,
    appCategory: [AppCategory.ALL, AppCategory.PROJECT_MANAGEMENT],
    showInAppStore: true,
  },
  ashby: {
    objectTypes: [ObjectType.All, ObjectType.Tasks],
    connectionType: AppConnectionType.PersonalOnly,
    displayName: 'Ashby',
    controlEnabled: true,
    shortname: 'ashby',
    description: 'Job Postings and Candidates',
    instantAvailable: InstantAvailableStatus.Personal,
    appCategory: [AppCategory.ALL, AppCategory.ATS],
    showInAppStore: true,
  },
  attio: {
    objectTypes: [ObjectType.All, ObjectType.Tasks],
    connectionType: AppConnectionType.PersonalOnly,
    displayName: 'Attio',
    controlEnabled: true,
    shortname: 'attio',
    description: 'People, Companies and Deals',
    instantAvailable: InstantAvailableStatus.Personal,
    appCategory: [AppCategory.ALL, AppCategory.CRM],
    showInAppStore: true,
  },
  bamboo_hr: {
    objectTypes: [],
    connectionType: AppConnectionType.Other,
    displayName: 'BambooHR',
    icon: <UIIcon name="bamboo_hr" size={28} type="apps" />,
    controlEnabled: true,
    shortname: 'bamboo_hr',
    description: 'Employee Directory',
    appCategory: [AppCategory.ALL, AppCategory.HRIS],
    showInAppStore: true,
  },
  bitbucket: {
    objectTypes: [ObjectType.All, ObjectType.Tasks],
    connectionType: AppConnectionType.PersonalOnly,
    displayName: 'Bitbucket',
    controlEnabled: true,
    shortname: 'bitbucket',
    description: 'Code',
    instantAvailable: InstantAvailableStatus.Personal,
    appCategory: [AppCategory.ALL, AppCategory.CODE],
    showInAppStore: true,
  },
  box: {
    objectTypes: [ObjectType.All, ObjectType.Files],
    connectionType: AppConnectionType.PersonalOnly,
    displayName: 'Box',
    controlEnabled: true,
    shortname: 'box',
    description: 'Files, Folders and Bookmarks',
    instantAvailable: InstantAvailableStatus.Personal,
    appCategory: [AppCategory.ALL, AppCategory.WIKIS_DOCS],
    showInAppStore: true,
  },
  coda: {
    objectTypes: [ObjectType.All, ObjectType.Files],
    connectionType: AppConnectionType.PersonalOnly,
    displayName: 'Coda',
    controlEnabled: true,
    shortname: 'coda',
    description: 'Documents',
    instantAvailable: InstantAvailableStatus.Personal,
    appCategory: [AppCategory.ALL, AppCategory.WIKIS_DOCS],
    showInAppStore: true,
  },
  confluence: {
    objectTypes: [ObjectType.All, ObjectType.Files],
    connectionType: AppConnectionType.PersonalOnly,
    displayName: 'Confluence',
    controlEnabled: true,
    shortname: 'confluence',
    description: 'Pages',
    instantAvailable: InstantAvailableStatus.Personal,
    appCategory: [AppCategory.ALL, AppCategory.WIKIS_DOCS],
    showInAppStore: true,
  },
  darwinbox: {
    objectTypes: [],
    connectionType: AppConnectionType.Other,
    displayName: 'Darwinbox',
    icon: <UIIcon name="darwinbox" size={28} type="apps" />,
    controlEnabled: true,
    shortname: 'darwinbox',
    description: 'Employee Directory',
    appCategory: [AppCategory.ALL, AppCategory.HRIS],
    showInAppStore: true,
  },
  docusign: {
    objectTypes: [ObjectType.All, ObjectType.Files],
    connectionType: AppConnectionType.PersonalOnly,
    displayName: 'Docusign',
    controlEnabled: true,
    shortname: 'docusign',
    description: 'Envelopes',
    instantAvailable: InstantAvailableStatus.Personal,
    appCategory: [AppCategory.ALL, AppCategory.WIKIS_DOCS],
    showInAppStore: true,
  },
  dropbox: {
    objectTypes: [ObjectType.All, ObjectType.Files],
    connectionType: AppConnectionType.PersonalOnly,
    displayName: 'Dropbox',
    controlEnabled: true,
    shortname: 'dropbox',
    description: 'Files, Folders and Paper Docs',
    instantAvailable: InstantAvailableStatus.Personal,
    appCategory: [AppCategory.ALL, AppCategory.WIKIS_DOCS],
    showInAppStore: true,
  },
  freshdesk: {
    objectTypes: [ObjectType.All, ObjectType.Tasks],
    connectionType: AppConnectionType.PersonalOnly,
    displayName: 'Freshdesk',
    controlEnabled: true,
    shortname: 'freshdesk',
    description: 'Tickets and Contacts',
    instantAvailable: InstantAvailableStatus.Personal,
    appCategory: [AppCategory.ALL, AppCategory.SUPPORT],
    showInAppStore: true,
  },
  front: {
    objectTypes: [ObjectType.All, ObjectType.Files],
    connectionType: AppConnectionType.PersonalOnly,
    displayName: 'Front',
    controlEnabled: true,
    shortname: 'front',
    description: 'Conversations',
    instantAvailable: InstantAvailableStatus.Personal,
    appCategory: [AppCategory.ALL, AppCategory.SUPPORT],
    showInAppStore: true,
  },
  gcal: {
    objectTypes: [ObjectType.All, ObjectType.Calendar],
    connectionType: AppConnectionType.AccessControl,
    displayName: 'Google Calendar',
    controlEnabled: true,
    shortname: 'gcal',
    parent: 'gworkspace',
    description: 'Events',
    instantAvailable: InstantAvailableStatus.Both,
    appCategory: [AppCategory.ALL, AppCategory.CALENDAR],
    showInAppStore: true,
  },
  gdrive: {
    objectTypes: [ObjectType.All, ObjectType.Files],
    connectionType: AppConnectionType.AccessControl,
    displayName: 'Google Drive',
    controlEnabled: true,
    shortname: 'gdrive',
    parent: 'gworkspace',
    description: 'Files',
    instantAvailable: InstantAvailableStatus.Both,
    appCategory: [AppCategory.ALL, AppCategory.WIKIS_DOCS, AppCategory.POPULAR],
    popularAppSortOrder: 3,
    showInAppStore: true,
  },
  gsites: {
    objectTypes: [],
    connectionType: AppConnectionType.OrgOnly,
    displayName: 'Google Sites',
    controlEnabled: true,
    shortname: 'gsites',
    description: 'Pages',
    instantAvailable: InstantAvailableStatus.Org,
    appCategory: [AppCategory.ALL, AppCategory.INTRANET],
    showInAppStore: true,
  },
  gusto: {
    objectTypes: [],
    connectionType: AppConnectionType.Other,
    displayName: 'Gusto',
    icon: <UIIcon name="gusto" size={28} type="apps" />,
    controlEnabled: true,
    shortname: 'gusto',
    description: 'Employee Directory',
    appCategory: [AppCategory.ALL, AppCategory.HRIS],
    showInAppStore: true,
  },
  gworkspace: {
    connectionType: AppConnectionType.OrgRestricted,
    displayName: 'Google Workspace',
    controlEnabled: false,
    shortname: 'gworkspace',
    description: 'Auth',
    showInAppStore: false,
  },
  'gworkspace-directory': {
    connectionType: AppConnectionType.Other,
    displayName: 'Google Workspace Directory',
    controlEnabled: false,
    shortname: 'gworkspace-directory',
    description: 'Sync users from Google Workspace Directory',
    showInAppStore: false,
  },
  gitbook: {
    objectTypes: [ObjectType.All, ObjectType.Files],
    connectionType: AppConnectionType.PersonalOnly,
    displayName: 'GitBook',
    controlEnabled: true,
    shortname: 'gitbook',
    description: 'Documents',
    instantAvailable: InstantAvailableStatus.Personal,
    appCategory: [AppCategory.ALL, AppCategory.WIKIS_DOCS],
    showInAppStore: true,
  },
  github: {
    objectTypes: [ObjectType.All, ObjectType.Files, ObjectType.Tasks],
    connectionType: AppConnectionType.PersonalOnly,
    displayName: 'GitHub',
    controlEnabled: true,
    shortname: 'github',
    description: 'Code, Issues, Pull Requests and Repositories',
    instantAvailable: InstantAvailableStatus.Personal,
    appCategory: [AppCategory.ALL, AppCategory.CODE],
    showInAppStore: true,
  },
  gitlab: {
    objectTypes: [ObjectType.All, ObjectType.Files, ObjectType.Tasks],
    connectionType: AppConnectionType.AccessControl,
    displayName: 'GitLab',
    controlEnabled: true,
    shortname: 'gitlab',
    description: 'Code, Issues, Merge Requests, Milestones, Projects and Wikis',
    instantAvailable: InstantAvailableStatus.Both,
    appCategory: [AppCategory.ALL, AppCategory.CODE],
    showInAppStore: true,
  },
  gmail: {
    objectTypes: [ObjectType.All, ObjectType.Files, ObjectType.Messages],
    connectionType: AppConnectionType.AccessControl,
    displayName: 'Gmail',
    controlEnabled: true,
    shortname: 'gmail',
    parent: 'gworkspace',
    description: 'Emails',
    instantAvailable: InstantAvailableStatus.Both,
    appCategory: [AppCategory.ALL, AppCategory.MESSAGING],
    showInAppStore: true,
  },
  guru: {
    objectTypes: [ObjectType.All, ObjectType.Files],
    connectionType: AppConnectionType.PersonalOnly,
    displayName: 'Guru',
    controlEnabled: true,
    shortname: 'guru',
    description: 'Cards, Collections, Folders and Templates',
    instantAvailable: InstantAvailableStatus.Personal,
    appCategory: [AppCategory.ALL, AppCategory.WIKIS_DOCS],
    showInAppStore: true,
  },
  helpjuice: {
    objectTypes: [ObjectType.All, ObjectType.Files],
    connectionType: AppConnectionType.AccessControl,
    displayName: 'Helpjuice',
    controlEnabled: true,
    shortname: 'helpjuice',
    description: 'Articles',
    instantAvailable: InstantAvailableStatus.Both,
    appCategory: [AppCategory.ALL, AppCategory.WIKIS_DOCS],
    showInAppStore: true,
  },
  helpscout: {
    objectTypes: [ObjectType.All, ObjectType.Files],
    connectionType: AppConnectionType.PersonalOnly,
    displayName: 'Help Scout',
    controlEnabled: true,
    shortname: 'helpscout',
    description: 'Articles and Conversations',
    instantAvailable: InstantAvailableStatus.Personal,
    appCategory: [AppCategory.ALL, AppCategory.SUPPORT],
    showInAppStore: true,
  },
  hubspot: {
    objectTypes: [ObjectType.All, ObjectType.Contacts],
    connectionType: AppConnectionType.PersonalOnly,
    displayName: 'HubSpot',
    controlEnabled: true,
    shortname: 'hubspot',
    description:
      'Contacts, Companies, Deals, Calls, Emails, Meetings and Notes',
    instantAvailable: InstantAvailableStatus.Personal,
    appCategory: [AppCategory.ALL, AppCategory.CRM],
    showInAppStore: true,
  },
  intercom: {
    objectTypes: [ObjectType.All, ObjectType.Messages, ObjectType.Contacts],
    connectionType: AppConnectionType.PersonalOnly,
    displayName: 'Intercom',
    controlEnabled: true,
    shortname: 'intercom',
    description: 'Articles, Contacts, Conversations and Tickets',
    instantAvailable: InstantAvailableStatus.Personal,
    appCategory: [AppCategory.ALL, AppCategory.SUPPORT],
    showInAppStore: true,
  },
  jira: {
    objectTypes: [ObjectType.All, ObjectType.Tasks],
    connectionType: AppConnectionType.PersonalOnly,
    displayName: 'Jira',
    controlEnabled: true,
    shortname: 'jira',
    description: 'Issues and Projects',
    instantAvailable: InstantAvailableStatus.Personal,
    appCategory: [AppCategory.ALL, AppCategory.PROJECT_MANAGEMENT],
    showInAppStore: true,
  },
  linear: {
    objectTypes: [ObjectType.All, ObjectType.Files],
    connectionType: AppConnectionType.PersonalOnly,
    displayName: 'Linear',
    controlEnabled: true,
    shortname: 'linear',
    description: 'Issues, Projects and Documents',
    instantAvailable: InstantAvailableStatus.Personal,
    appCategory: [AppCategory.ALL, AppCategory.PROJECT_MANAGEMENT],
    showInAppStore: true,
  },
  lucidchart: {
    objectTypes: [ObjectType.All],
    connectionType: AppConnectionType.PersonalOnly,
    displayName: 'Lucidchart',
    controlEnabled: true,
    shortname: 'lucidchart',
    description: 'Documents and Lucidspark Boards',
    instantAvailable: InstantAvailableStatus.Personal,
    appCategory: [AppCategory.ALL, AppCategory.PROJECT_MANAGEMENT],
    showInAppStore: true,
  },
  mailchimp: {
    objectTypes: [ObjectType.All, ObjectType.Tasks],
    connectionType: AppConnectionType.PersonalOnly,
    displayName: 'Mailchimp',
    controlEnabled: true,
    shortname: 'mailchimp',
    description: 'Campaigns, Members, Lists and Reports',
    instantAvailable: InstantAvailableStatus.Personal,
    appCategory: [AppCategory.ALL, AppCategory.MARKETING],
    showInAppStore: true,
  },
  miro: {
    objectTypes: [ObjectType.All, ObjectType.Files],
    connectionType: AppConnectionType.PersonalOnly,
    displayName: 'Miro',
    controlEnabled: true,
    shortname: 'miro',
    description: 'Boards',
    instantAvailable: InstantAvailableStatus.Personal,
    appCategory: [AppCategory.ALL, AppCategory.PROJECT_MANAGEMENT],
    showInAppStore: true,
  },
  msteams: {
    objectTypes: [ObjectType.All, ObjectType.Messages],
    connectionType: AppConnectionType.PersonalOnly,
    displayName: 'Microsoft Teams',
    controlEnabled: true,
    shortname: 'msteams',
    description: 'Messages',
    instantAvailable: InstantAvailableStatus.Personal,
    appCategory: [AppCategory.ALL, AppCategory.MESSAGING],
    showInAppStore: true,
  },
  mural: {
    objectTypes: [ObjectType.All, ObjectType.Tasks],
    connectionType: AppConnectionType.PersonalOnly,
    displayName: 'Mural',
    controlEnabled: true,
    shortname: 'mural',
    description: 'Murals, Rooms and Templates',
    instantAvailable: InstantAvailableStatus.Personal,
    appCategory: [AppCategory.ALL, AppCategory.WIKIS_DOCS],
    showInAppStore: true,
  },
  notion: {
    objectTypes: [ObjectType.All, ObjectType.Files],
    connectionType: AppConnectionType.PersonalOnly,
    displayName: 'Notion',
    controlEnabled: true,
    shortname: 'notion',
    description: 'Pages',
    instantAvailable: InstantAvailableStatus.Personal,
    appCategory: [AppCategory.ALL, AppCategory.WIKIS_DOCS, AppCategory.POPULAR],
    popularAppSortOrder: 2,
    showInAppStore: true,
  },
  outlook: {
    objectTypes: [ObjectType.All, ObjectType.Messages],
    connectionType: AppConnectionType.OrgOnly,
    displayName: 'Outlook',
    controlEnabled: true,
    shortname: 'outlook',
    description: 'Emails',
    instantAvailable: InstantAvailableStatus.Org,
    appCategory: [AppCategory.ALL, AppCategory.MESSAGING],
    showInAppStore: true,
  },
  onedrive: {
    objectTypes: [ObjectType.All, ObjectType.Files],
    connectionType: AppConnectionType.AccessControl,
    displayName: 'OneDrive',
    controlEnabled: true,
    shortname: 'onedrive',
    description: 'Files and Folders',
    instantAvailable: InstantAvailableStatus.Both,
    appCategory: [AppCategory.ALL, AppCategory.WIKIS_DOCS],
    showInAppStore: true,
  },
  paylocity: {
    objectTypes: [],
    connectionType: AppConnectionType.Other,
    displayName: 'Paylocity',
    icon: <UIIcon name="paylocity" size={28} type="apps" />,
    controlEnabled: true,
    shortname: 'paylocity',
    description: 'Employee Directory',
    appCategory: [AppCategory.ALL, AppCategory.HRIS],
    showInAppStore: true,
  },
  pipedrive: {
    objectTypes: [ObjectType.All, ObjectType.Contacts, ObjectType.Tasks],
    connectionType: AppConnectionType.PersonalOnly,
    displayName: 'Pipedrive',
    controlEnabled: true,
    shortname: 'pipedrive',
    description: 'Leads, Deals, Products, Files, Organizations, and People',
    instantAvailable: InstantAvailableStatus.Personal,
    appCategory: [AppCategory.ALL, AppCategory.CRM],
    showInAppStore: true,
  },
  quip: {
    objectTypes: [ObjectType.All, ObjectType.Files],
    connectionType: AppConnectionType.PersonalOnly,
    displayName: 'Quip',
    controlEnabled: true,
    shortname: 'quip',
    description: 'Files and Folders',
    instantAvailable: InstantAvailableStatus.Personal,
    appCategory: [AppCategory.ALL, AppCategory.WIKIS_DOCS],
    showInAppStore: true,
  },
  rippling: {
    objectTypes: [],
    connectionType: AppConnectionType.Other,
    displayName: 'Rippling',
    icon: <UIIcon name="rippling" size={28} type="apps" />,
    controlEnabled: true,
    shortname: 'rippling',
    description: 'Employee Directory',
    appCategory: [AppCategory.ALL, AppCategory.HRIS],
    showInAppStore: true,
  },
  salesforce: {
    objectTypes: [
      ObjectType.All,
      ObjectType.Files,
      ObjectType.Tasks,
      ObjectType.Contacts,
    ],
    connectionType: AppConnectionType.PersonalOnly,
    displayName: 'Salesforce',
    controlEnabled: true,
    shortname: 'salesforce',
    description:
      'Accounts, Contacts, Leads, Opportunities, Cases, Reports and Knowledge Articles',
    instantAvailable: InstantAvailableStatus.Personal,
    appCategory: [AppCategory.ALL, AppCategory.CRM, AppCategory.POPULAR],
    popularAppSortOrder: 5,
    showInAppStore: true,
  },
  servicenow: {
    connectionType: AppConnectionType.PersonalOnly,
    displayName: 'ServiceNow',
    controlEnabled: true,
    shortname: 'servicenow',
    description: 'Knowledge Articles',
    instantAvailable: InstantAvailableStatus.Personal,
    appCategory: [AppCategory.ALL, AppCategory.SUPPORT],
    showInAppStore: true,
  },
  sharepoint: {
    objectTypes: [ObjectType.All, ObjectType.Files],
    connectionType: AppConnectionType.PersonalOnly,
    displayName: 'SharePoint',
    controlEnabled: true,
    shortname: 'sharepoint',
    description: 'Sites, Pages, Lists and List Items',
    instantAvailable: InstantAvailableStatus.Personal,
    appCategory: [AppCategory.ALL, AppCategory.INTRANET],
    showInAppStore: true,
  },
  shortcut: {
    objectTypes: [ObjectType.All, ObjectType.Tasks],
    connectionType: AppConnectionType.PersonalOnly,
    displayName: 'Shortcut',
    controlEnabled: true,
    shortname: 'shortcut',
    description: 'Stories, Epics and Projects',
    instantAvailable: InstantAvailableStatus.Personal,
    appCategory: [AppCategory.ALL, AppCategory.PROJECT_MANAGEMENT],
    showInAppStore: true,
  },
  simpplr: {
    connectionType: AppConnectionType.PersonalOnly,
    displayName: 'Simpplr',
    controlEnabled: true,
    shortname: 'simpplr',
    description: 'Sites',
    instantAvailable: InstantAvailableStatus.Personal,
    appCategory: [AppCategory.ALL, AppCategory.INTRANET],
    showInAppStore: true,
  },
  slab: {
    connectionType: AppConnectionType.OrgOnly,
    displayName: 'Slab',
    controlEnabled: true,
    shortname: 'slab',
    description: 'Posts and Topics',
    instantAvailable: InstantAvailableStatus.Org,
    appCategory: [AppCategory.ALL, AppCategory.WIKIS_DOCS],
    showInAppStore: true,
  },
  slack: {
    objectTypes: [ObjectType.All, ObjectType.Files, ObjectType.Messages],
    connectionType: AppConnectionType.PersonalOnly,
    displayName: 'Slack',
    controlEnabled: true,
    shortname: 'slack',
    description: 'Messages, Files, Channels and DMs',
    instantAvailable: InstantAvailableStatus.Personal,
    appCategory: [AppCategory.ALL, AppCategory.MESSAGING, AppCategory.POPULAR],
    popularAppSortOrder: 1,
    showInAppStore: true,
  },
  smartsheet: {
    objectTypes: [ObjectType.All],
    connectionType: AppConnectionType.PersonalOnly,
    displayName: 'Smartsheet',
    controlEnabled: true,
    shortname: 'smartsheet',
    description:
      'Sheets, Dashboards, Discussions, Reports, Attachments and Folders',
    instantAvailable: InstantAvailableStatus.Personal,
    appCategory: [AppCategory.ALL, AppCategory.PROJECT_MANAGEMENT],
    showInAppStore: true,
  },
  stackoverflowteams: {
    objectTypes: [ObjectType.All, ObjectType.Answers, ObjectType.Articles],
    connectionType: AppConnectionType.AccessControl,
    displayName: 'Stack Overflow for Teams',
    controlEnabled: true,
    shortname: 'stackoverflowteams',
    description: 'Questions, Answers and Articles',
    instantAvailable: InstantAvailableStatus.Both,
    appCategory: [AppCategory.ALL, AppCategory.MESSAGING],
    showInAppStore: true,
  },
  trello: {
    objectTypes: [ObjectType.All, ObjectType.Tasks],
    connectionType: AppConnectionType.PersonalOnly,
    displayName: 'Trello',
    controlEnabled: true,
    shortname: 'trello',
    description: 'Cards and Boards',
    instantAvailable: InstantAvailableStatus.Personal,
    appCategory: [AppCategory.ALL, AppCategory.PROJECT_MANAGEMENT],
    showInAppStore: true,
  },
  website: {
    objectTypes: [],
    connectionType: AppConnectionType.AccessControl,
    displayName: 'Website',
    controlEnabled: true,
    shortname: 'website',
    description: 'Website Content',
    instantAvailable: InstantAvailableStatus.Both,
    appCategory: [AppCategory.ALL, AppCategory.WEBSITE, AppCategory.POPULAR],
    popularAppSortOrder: 4,
    showInAppStore: true,
  },
  workday: {
    objectTypes: [],
    connectionType: AppConnectionType.Other,
    displayName: 'Workday',
    icon: <UIIcon name="workday" size={28} type="apps" />,
    controlEnabled: true,
    shortname: 'workday',
    description: 'Employee Directory',
    appCategory: [AppCategory.ALL, AppCategory.HRIS],
    showInAppStore: true,
  },
  wrike: {
    objectTypes: [ObjectType.All, ObjectType.Tasks],
    connectionType: AppConnectionType.PersonalOnly,
    displayName: 'Wrike',
    controlEnabled: true,
    shortname: 'wrike',
    description: 'Tasks',
    instantAvailable: InstantAvailableStatus.Personal,
    appCategory: [AppCategory.ALL, AppCategory.WIKIS_DOCS],
    showInAppStore: true,
  },
  zendesk: {
    objectTypes: [ObjectType.All, ObjectType.Tasks],
    connectionType: AppConnectionType.AccessControl,
    displayName: 'Zendesk',
    controlEnabled: true,
    shortname: 'zendesk',
    description: 'Tickets, Articles and Posts',
    instantAvailable: InstantAvailableStatus.Both,
    appCategory: [AppCategory.ALL, AppCategory.SUPPORT],
    showInAppStore: true,
  },
  zoho_crm: {
    objectTypes: [ObjectType.All, ObjectType.Files],
    connectionType: AppConnectionType.PersonalOnly,
    displayName: 'Zoho CRM',
    controlEnabled: true,
    shortname: 'zoho_crm',
    description: 'Leads, Accounts, Contacts, Deals and Events',
    instantAvailable: InstantAvailableStatus.Personal,
    appCategory: [AppCategory.ALL, AppCategory.CRM],
    showInAppStore: true,
  },
  zoho_projects: {
    objectTypes: [ObjectType.All, ObjectType.Files],
    connectionType: AppConnectionType.PersonalOnly,
    displayName: 'Zoho Projects',
    controlEnabled: true,
    shortname: 'zoho_projects',
    description: 'Tasks and Projects',
    instantAvailable: InstantAvailableStatus.Personal,
    appCategory: [AppCategory.ALL, AppCategory.PROJECT_MANAGEMENT],
    showInAppStore: true,
  },
};

export const appsDefinitionFactory = (
  flags: Partial<FeatureFlags>
): AppDefinitions => {
  const defsCopy = { ...defs };

  for (const [name, def] of Object.entries(defsCopy)) {
    let integrationEnabled = flags[`${name}Integration`] ?? true;
    if (name === 'zoho_crm') {
      integrationEnabled = flags.zohoCrmIntegration ?? true;
    }

    if (name === 'zoho_projects') {
      integrationEnabled = flags.zohoProjectsIntegration ?? true;
    }

    if (!integrationEnabled || DISABLED_APPS.includes(name)) {
      // This is generally safe
      // eslint-disable-next-line @typescript-eslint/no-dynamic-delete
      delete defsCopy[name as AppName];
      continue;
    }

    const controlEnabled = flags[`${name}IntegrationControl`];
    if (controlEnabled !== undefined) {
      def.controlEnabled = controlEnabled;
    }
  }

  return defsCopy;
};

export type AppName =
  | 'aha'
  | 'airtable'
  | 'all'
  | 'asana'
  | 'ashby'
  | 'attio'
  | 'bamboo_hr'
  | 'baseLlm'
  | 'bitbucket'
  | 'box'
  | 'brandfolder'
  | 'clickup'
  | 'coda'
  | 'confluence'
  | 'darwinbox'
  | 'docusign'
  | 'dropbox'
  | 'freshdesk'
  | 'front'
  | 'gcal'
  | 'gdrive'
  | 'gitbook'
  | 'github'
  | 'gitlab'
  | 'gmail'
  | 'gsites'
  | 'guru'
  | 'gusto'
  | 'gworkspace-directory'
  | 'gworkspace'
  | 'helpjuice'
  | 'helpscout'
  | 'hubspot'
  | 'intercom'
  | 'jira'
  | 'linear'
  | 'lucidchart'
  | 'mailchimp'
  | 'miro'
  | 'monday'
  | 'msteams'
  | 'mural'
  | 'notion'
  | 'onedrive'
  | 'outlook'
  | 'paylocity'
  | 'pipedrive'
  | 'quip'
  | 'readme'
  | 'rippling'
  | 'salesforce'
  | 'servicenow'
  | 'sharepoint'
  | 'shortcut'
  | 'simpplr'
  | 'slab'
  | 'slack'
  | 'smartsheet'
  | 'stackoverflowteams'
  | 'system'
  | 'trello'
  | 'web'
  | 'website'
  | 'workday'
  | 'wrike'
  | 'zendesk'
  | 'zoho_crm'
  | 'zoho_projects';

export const FINCH_APPS = new Set([
  'bamboo_hr',
  'darwinbox',
  'gusto',
  'paylocity',
  'rippling',
  'workday',
]);

export const GOOGLE_APPS = new Set(['gmail', 'gcal', 'gdrive']);

export function useAppDefinitions(): AppDefinitions {
  const allFlags = useAllFlags();
  return useMemo(() => appsDefinitionFactory(allFlags), [allFlags]);
}

export function useAppDefinition(name: AppName): AppDefinition | undefined {
  return useAppDefinitions()[name];
}

export const getAppDisplayName = (shortname: AppName): string => {
  return defs[shortname]?.displayName ?? shortname;
};
