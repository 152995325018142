import './QAMessageListView.scss';

import React, { FC, useCallback, useEffect, useMemo, useRef } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import {
  Conversation,
  QAMessage,
  StaticAnswerType,
} from '../../models/QAmodels';
import { useQAController } from '../../scripts/QAController';
import { useBots } from '../../scripts/hooks/bots';
import { logDebug, logError } from '../../scripts/utils';
import { Loading, LoadingSize } from '../controls/Loading/Loading';
import { QAMessageView } from './QAMessageView';
import 'moment-timezone';
import { QAStreamingMessage } from './QAStreamingMessage';
import { QATopicDivider } from './topicDivider/QATopicDivider';

export const QAMessageListView: FC = () => {
  const qaController = useQAController();
  const { bots } = useBots();

  const messages = qaController.useMessages();
  const { isFetchingMessages, hasMoreMessages } =
    qaController.useProgressStage();

  const { isNewTopic } = qaController.getIsNewTopic();

  const messagesEndRef = useRef<HTMLDivElement>(null);
  const newTopicRef = useRef<HTMLDivElement>(null);
  const firstRender = useRef(true);

  const conversations = useMemo(() => {
    const conversationMap = messages.reduce<Record<string, Conversation>>(
      (acc, curr) => {
        if (!acc[curr.conversation_id]) {
          acc[curr.conversation_id] = {
            conversation_id: curr.conversation_id,
            conversation_timestamp: curr.conversation_timestamp,
            topicTitle: curr.topic_title,
            visibility: curr.visibility,
            messages: [],
          };
        }

        const conversation = acc[curr.conversation_id]!;

        if (!conversation.topicTitle) {
          conversation.topicTitle = curr.topic_title;
        }

        conversation.messages.push(curr);
        conversation.conversation_timestamp = Math.min(
          conversation.conversation_timestamp,
          curr.conversation_timestamp
        );

        return acc;
      },
      {}
    );

    // Convert to array and sort by conversation_timestamp
    const sortedConversations = Object.values(conversationMap).sort(
      (a, b) => a.conversation_timestamp - b.conversation_timestamp
    );

    for (const conversation of sortedConversations) {
      const { messages: conversationMsgs } = conversation;

      // Step 1: Group messages by `message_id`
      const groupedMessages = conversationMsgs.reduce<
        Record<string, QAMessage[]>
      >((groups, message) => {
        (groups[message.message_id] ??= groups[message.message_id] ?? []).push(
          message
        );

        return groups;
      }, {});

      if (Object.keys(groupedMessages).length === 0) {
        continue;
      }

      // Sort each group by `tsSentAt`
      for (const group of Object.values(groupedMessages))
        group.sort((a, b) => a.tsSentAt - b.tsSentAt);

      // Step 2 & 3: Create an array of keys with the earliest `tsSentAt` and sort it
      const sortedKeys = Object.keys(groupedMessages)
        .map((key) => {
          const msgs = groupedMessages[key];
          return {
            key,
            minTsSentAt:
              Array.isArray(msgs) && msgs.length > 0
                ? Math.min(...msgs.map((msg) => msg.tsSentAt))
                : Number.POSITIVE_INFINITY,
          };
        })
        .sort((a, b) => a.minTsSentAt - b.minTsSentAt)
        .map((item) => item.key);

      // Step 4: Concatenate the sorted groups
      const result = [] as QAMessage[];
      for (const key of sortedKeys) {
        const tmpMsgs = groupedMessages[key];

        if (Array.isArray(tmpMsgs) && tmpMsgs.length > 0) {
          result.push(...tmpMsgs);
        }
      }

      conversation.messages = result;
    }

    return sortedConversations;
  }, [messages]);

  const lastConversationId = useMemo(() => {
    return conversations[conversations.length - 1]?.messages[0]
      ?.conversation_id;
  }, [conversations]);

  const isOnboardingConversation = useCallback(
    (conversationMessages: QAMessage[]) => {
      return conversationMessages.some(
        (msg) =>
          // eslint-disable-next-line @cspell/spellchecker
          msg.extraData?.staticAnswerType ===
            StaticAnswerType.ONBOARDING_INITAL ||
          msg.extraData?.staticAnswerType ===
            StaticAnswerType.ONBOARDING_ASK_QUESTION
      );
    },
    []
  );

  const fetchMoreMessages = useCallback(() => {
    logDebug('Infinite scroll trigger fetch');
    qaController.fetchHistoryMessages().catch(logError);
  }, [qaController]);

  useEffect(() => {
    if (firstRender.current) {
      firstRender.current = false;
      return;
    }

    if (newTopicRef.current) {
      newTopicRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [isNewTopic]);

  // Don't show new topic if onboarding messages
  useEffect(() => {
    if (messages[messages.length - 1]?.extraData?.staticAnswerType) {
      qaController.setIsNewTopic(false);
    }
  }, [messages, qaController]);

  return (
    <div
      className="qaMessageList overflow-y-scroll scrollbar scrollbar-track-white scrollbar-thumb-gray-30 scrollbar-thin"
      id="qaMessageListId"
    >
      <InfiniteScroll
        dataLength={messages.length}
        hasMore={hasMoreMessages}
        inverse
        loader={<div />}
        next={fetchMoreMessages}
        scrollableTarget="qaMessageListId"
      >
        {conversations.map(
          (
            {
              conversation_id,
              conversation_timestamp,
              messages: conversationMessages,
              topicTitle,
              visibility,
            },
            conversationIndex
          ) => (
            <div key={conversation_id}>
              <div className="stickyDivider z-10">
                {!isOnboardingConversation(conversationMessages) && (
                  <QATopicDivider
                    conversationId={conversation_id}
                    isEditable
                    isNewTopic={false}
                    topicTimestamp={conversation_timestamp}
                    topicTitle={topicTitle}
                    visibility={visibility}
                  />
                )}
              </div>
              {conversationIndex === 0 && isFetchingMessages && (
                <Loading size={LoadingSize.Small} />
              )}

              {conversationMessages.map((message, messageIndex) => (
                <QAMessageView
                  bots={bots}
                  key={message.row_id}
                  qaMessage={message}
                  refRelatedCollapsed={
                    !(
                      conversationIndex === conversations.length - 1 &&
                      messageIndex === conversationMessages.length - 1
                    )
                  }
                  showAllSources
                />
              ))}
            </div>
          )
        )}

        <QAStreamingMessage bots={bots} topic_id={lastConversationId} />
        {isNewTopic && (
          <div ref={newTopicRef}>
            <QATopicDivider isNewTopic />
          </div>
        )}
        <div ref={messagesEndRef} />
      </InfiniteScroll>
    </div>
  );
};
