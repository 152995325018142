import './LoadingSplash.scss';
import React, { FC, useEffect, useState } from 'react';
import { SupportLink } from '../../components/links/links';
import { useTimeoutCreator } from '../../scripts/hooks';
import { logError } from '../../scripts/utils';
import { LeenaChatBotBubble } from '../controls/LeenaChatBotScript/LeenaChatBotScript';
import { Loading, LoadingSize } from '../controls/Loading/Loading';
import { UIIcon } from '../controls/ui/UIIcon/UIIcon';

const enum LoadState {
  // eslint-disable-next-line @typescript-eslint/no-shadow
  Loading,
  Slow,
  WayTooSlow,
  Failed,
}

const slowMessageDelay = 5000;
const failedMessageDelay = 50_000;

export const LoadingSplash: FC = () => {
  const [state, setState] = useState(LoadState.Loading);
  const setTimeout = useTimeoutCreator();

  useEffect(() => {
    setTimeout(() => {
      setState(LoadState.Slow);
    }, slowMessageDelay);

    setTimeout(() => {
      setState(LoadState.Failed);
      logError(new Error('Website failed to load due to latency'));
    }, failedMessageDelay);
  }, [setTimeout]);

  return (
    <div className="loadingSplash">
      {state === LoadState.Failed ? (
        <>
          <UIIcon className="icon" name="sad-face" size={200} />
          <span>
            Dashworks failed to load, please check your internet connection and
            try again, if this issue persists please reach out to our{' '}
            <SupportLink />.
          </span>
        </>
      ) : (
        <Loading
          size={LoadingSize.Huge}
          text={state === LoadState.Loading ? 'Loading...' : 'Still loading...'}
        />
      )}
      <LeenaChatBotBubble />
    </div>
  );
};
