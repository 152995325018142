import { invokeFastApi } from './fastapi';

/**
 * Disconnect a connected app.
 */
export const disconnectApp = async (
  appName: string,
  isOrg = false,
  appId?: number
): Promise<void> => {
  const query: {
    admin?: number;
    appId?: number;
  } = {};

  if (isOrg) {
    query.admin = 1;
  }

  if (appId) {
    query.appId = appId;
  }

  await invokeFastApi({
    path: `/app/disconnect_app_name/${appName}`,
    method: 'DELETE',
    queryParams: query,
  });
};

interface AuthCodeData {
  auth_session?: string;
  auth_flag?: string;
  api_key?: string;
  admin?: boolean;
  custom_domain?: string;
  site_id?: string;
  subdomain?: string;
  team_slug?: string;
  url?: string;
  // Google workspace only
  marketplace?: true;
  email?: string;
  appId?: number;
  v2?: boolean;
}

interface AuthUserPassData {
  username: string;
  password: string;
  admin?: boolean;
  appId?: number;
  v2?: boolean;
}

export type AuthCodeArgs = AuthCodeData | AuthUserPassData;

export const updateAuthCode = async (
  appName: string,
  code: AuthCodeArgs
): Promise<void> => {
  await invokeFastApi({
    path: `/app/install/${appName}`,
    method: 'PUT',
    body: code,
  });
};
