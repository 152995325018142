import React, { FC, useCallback, useEffect, useRef, useState } from 'react';
import { trackEvent } from '../../extra/sharedMethods';
import { UploadedFile } from '../../models/File';
import { SupportedLlm } from '../../models/User';
import { invokeFastApi } from '../../scripts/apis/fastapi';
import { AnalyticsEvent } from '../../scripts/constants/analytics-event';
import { useToaster, useUserSafe } from '../../scripts/hooks';
import { ConnectedApps } from '../../scripts/hooks/sortedInstantApps';
import { useWorkflowById } from '../../scripts/hooks/workflows';
import { Answer } from '../../scripts/models/answers';
import { getSourcesFromFilters } from '../../scripts/sources/common';
import { setFiltersFromWorkflowSources } from '../../scripts/sources/workflow';
import { logError } from '../../scripts/utils';
import { LLMSelectPopoverBtn } from '../LLMSelect/LLMSelectPopoverBtn';
import { SourcesFilterSwitch } from '../SourcesFilterSwitch/SourcesFilterSwitch';
import { Loading } from '../controls/Loading/Loading';
import { UIButton } from '../controls/ui/UIButton/UIButton';
import { UITextArea } from '../controls/ui/UITextArea/UITextArea';
import { UITextbox } from '../controls/ui/UITextbox/UITextbox';
import { useQATextInputBoxStates } from '../jit-qa/textInputBox/QATextInputBoxStates';
import { Tabs, TabsList, TabsTrigger } from '../shadcn/lib/components/tabs';
import { IInnerComponent } from './QAWorkflowTemplateModal';

export interface BaseWorkflowTemplateFormData {
  title: string;
  description?: string;
  visibility: 'PRIVATE' | 'PUBLIC';
  preferred_llm: SupportedLlm | null;
  template: string;
}

interface IEditWorkflowTemplate extends IInnerComponent {
  duplicate: boolean;
  setOpenPaywallModal(): void;
}

// eslint-disable-next-line max-lines-per-function
export const EditWorkflowTemplate: FC<IEditWorkflowTemplate> = ({
  duplicate,
  workflowId,
  onClose,
  setOpenPaywallModal,
}: IEditWorkflowTemplate) => {
  const user = useUserSafe();

  const { loading, workflow } = useWorkflowById(workflowId);
  const toaster = useToaster();

  const { allSkills, allSkillsUserApps, allFiles, allAnswers } =
    useQATextInputBoxStates();

  const previousAllSkillsRef = useRef(allSkills);

  const [formData, setFormData] = useState<BaseWorkflowTemplateFormData>({
    title: '',
    description: '',
    visibility: 'PUBLIC',
    preferred_llm:
      user.preferredLlm ??
      user.orgByOrgId.preferredLlm ??
      SupportedLlm.OPENAI_GPT4_OMNI,
    template: '',
  });

  const [isBaseLLM, setIsBaseLLM] = useState(false);
  const [skillFilters, setSkillFilters] = useState<ConnectedApps[]>(allSkills);
  const [fileFilters, setFileFilters] = useState<UploadedFile[]>(allFiles);
  const [answerFilters, setAnswerFilters] = useState<Answer[]>(allAnswers);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleInputChange = useCallback(
    (
      name: keyof BaseWorkflowTemplateFormData,
      value: SupportedLlm | string | null
    ) => {
      setFormData((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    },
    []
  );

  const handleSubmit = useCallback(async () => {
    const { isValid, error } = validateFormDataAndSources(
      formData,
      isBaseLLM,
      skillFilters,
      fileFilters,
      answerFilters
    );

    if (!isValid || !workflowId) {
      toaster.failure(error);
      return;
    }

    setIsSubmitting(true);

    const sources = getSourcesFromFilters(
      isBaseLLM,
      skillFilters,
      fileFilters,
      answerFilters,
      allSkills,
      allFiles,
      allAnswers
    );

    const bodyData = {
      ...formData,
      sources,
    };

    try {
      await (duplicate
        ? invokeFastApi({
            path: '/prompts/create',
            method: 'POST',
            body: bodyData,
          })
        : invokeFastApi({
            path: `/prompts/${workflowId}`,
            method: 'PATCH',
            body: bodyData,
          }));

      toaster.success('Workflow updated successfully');
    } catch (fetchError) {
      logError(fetchError);
      toaster.failure('There was an error updating the workflow');
    }

    trackEvent(AnalyticsEvent.WorkflowUpdated, {
      formData,
      sources,
    });

    setIsSubmitting(false);
    onClose();
  }, [
    formData,
    workflowId,
    onClose,
    isBaseLLM,
    skillFilters,
    fileFilters,
    answerFilters,
    allSkills,
    allFiles,
    allAnswers,
    duplicate,
    toaster,
  ]);

  useEffect(() => {
    if (workflow) {
      setFormData({
        title: duplicate ? `Copy: ${workflow.title}` : workflow.title,
        description: workflow.description ?? '',
        visibility: workflow.visibility,
        preferred_llm: workflow.preferred_llm,
        template: workflow.template,
      });

      setFiltersFromWorkflowSources(
        setIsBaseLLM,
        setSkillFilters,
        setFileFilters,
        setAnswerFilters,
        workflow,
        user.email,
        previousAllSkillsRef.current,
        allFiles,
        allAnswers
      );
    }
  }, [workflow, duplicate, user.email, allFiles, allAnswers]);

  useEffect(() => {
    if (!workflow) {
      if (previousAllSkillsRef.current.length !== allSkills.length) {
        setSkillFilters(allSkills);
      }

      // Update the ref after making the comparison
      previousAllSkillsRef.current = allSkills;
    }
  }, [allSkills, workflow]);

  useEffect(() => {
    if (!workflow) {
      setFileFilters(allFiles);
    }
  }, [allFiles, workflow]);

  useEffect(() => {
    if (!workflow) {
      setAnswerFilters(allAnswers);
    }
  }, [allAnswers, workflow]);

  if (loading)
    return (
      <div className="mt-40">
        <Loading />
      </div>
    );

  return (
    <form onSubmit={handleSubmit}>
      <div className="flex flex-col gap-6 max-h-[420px]">
        <div>
          <label>Title</label>
          <UITextbox
            className="mt-2"
            maxLength={60}
            onChange={(value) => {
              handleInputChange('title', value);
            }}
            placeholder="Enter title"
            type="text"
            value={formData.title}
          />
        </div>
        <div>
          <label>Description</label>
          <UITextbox
            className="mt-2"
            onChange={(value) => {
              handleInputChange('description', value);
            }}
            placeholder="Enter description"
            type="text"
            value={formData.description ?? ''}
          />
        </div>

        <div className="flex gap-6">
          <div>
            <label>Sources</label>
            <div className="mt-2">
              <SourcesFilterSwitch
                allAnswers={allAnswers}
                allFiles={allFiles}
                allSkills={allSkills}
                allSkillsUserApps={allSkillsUserApps}
                answerFilters={answerFilters}
                fileFilters={fileFilters}
                isBaseLLM={isBaseLLM}
                setAnswerFilters={setAnswerFilters}
                setFileFilters={setFileFilters}
                setIsBaseLLM={setIsBaseLLM}
                setSkillFilters={setSkillFilters}
                showPopupFromBottom
                skillFilters={skillFilters}
              />
            </div>
          </div>
          <div>
            <label>AI Model</label>
            <div className="mt-2">
              <LLMSelectPopoverBtn
                selectedLlm={formData.preferred_llm}
                setOpenPaywallModal={setOpenPaywallModal}
                setSelectedLlm={(newLlm) => {
                  handleInputChange('preferred_llm', newLlm);
                }}
              />
            </div>
          </div>
        </div>

        <div>
          <label>Visibility</label>
          <Tabs className="mt-2" value={formData.visibility}>
            <TabsList className="grid w-fit grid-cols-2">
              <TabsTrigger
                onClick={() => {
                  handleInputChange('visibility', 'PRIVATE');
                }}
                value="PRIVATE"
              >
                Private
              </TabsTrigger>
              <TabsTrigger
                onClick={() => {
                  handleInputChange('visibility', 'PUBLIC');
                }}
                value="PUBLIC"
              >
                Organization
              </TabsTrigger>
            </TabsList>
          </Tabs>
        </div>

        <div>
          <label>Preview</label>
          <div className="mt-2">
            <UITextArea
              maxRows={6}
              multilineExpandable
              onChange={(value) => {
                handleInputChange('template', value);
              }}
              value={formData.template}
            />
          </div>
          <div className="mt-1 text-smoke-10 text-xs">
            {
              'Tip: Change specific keywords into generic keywords for ease of use. Eg. Pizza > Food Name'
            }
          </div>
        </div>
      </div>
      <div className="fixed bottom-0 left-0 right-0 flex justify-between p-6">
        <UIButton onClick={onClose} type="secondary">
          Cancel
        </UIButton>
        <UIButton onClick={handleSubmit} processing={isSubmitting}>
          Save
        </UIButton>
      </div>
    </form>
  );
};

export const validateFormDataAndSources = (
  data: BaseWorkflowTemplateFormData,
  isBaseLLM: boolean,
  skillFilters: ConnectedApps[],
  fileFilters: UploadedFile[],
  answerFilters: Answer[]
): { isValid: boolean; error: string } => {
  if (!data.title.trim()) {
    return {
      isValid: false,
      error: 'Title cannot be empty',
    };
  }

  if (
    !isBaseLLM &&
    skillFilters.length === 0 &&
    fileFilters.length === 0 &&
    answerFilters.length === 0
  ) {
    return {
      isValid: false,
      error: 'At least one source is required, if Apps tab is selected',
    };
  }

  if (!data.template.trim()) {
    return {
      isValid: false,
      error: 'Preview Template cannot be empty',
    };
  }

  return {
    isValid: true,
    error: '',
  };
};
