import debounce from 'lodash/debounce';
import React, { FC, useCallback, useEffect, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useQAController } from '../../../scripts/QAController';
import { logError, truncateString } from '../../../scripts/utils';
import { Loading, LoadingSize } from '../../controls/Loading/Loading';
import { UITextbox } from '../../controls/ui/UITextbox/UITextbox';
import { SidebarLink } from './Sidebar';

interface TopicsListProps {
  topicsOpen: boolean;
}

export const TopicsList: FC<TopicsListProps> = ({
  topicsOpen,
}: TopicsListProps) => {
  const [page, setPage] = useState(0);
  const [query, setQuery] = useState('');
  const qaController = useQAController();
  const topics = qaController.useTopics();

  const { hasMoreTopics } = qaController.useProgressStage();
  useEffect(() => {
    qaController
      .fetchTopics({
        page: 0,
        query: '',
      })
      .catch((error) => {
        logError(error);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedFetch = useCallback(
    debounce((newQuery: string) => {
      qaController
        .fetchTopics({
          page: 0,
          query: newQuery,
        })
        .catch((error) => {
          logError(error);
        });
    }, 150),
    [qaController, logError]
  );

  const handleSearch = useCallback(
    (newQuery: string) => {
      setQuery(newQuery);
      setPage(0);

      debouncedFetch(newQuery);
    },
    [debouncedFetch]
  );

  const fetchMoreTopics = useCallback(() => {
    qaController
      .fetchTopics({
        page: page + 1,
        query,
        fetchMore: true,
      })
      .catch((error) => {
        logError(error);
      });

    setPage(page + 1);
  }, [qaController, page, query]);

  return (
    <div
      className="flex-grow min-h-[200px] overflow-auto mt-2"
      id="topicScrollableDiv"
    >
      {topicsOpen && (
        <>
          <div className="px-2 sticky top-0">
            <UITextbox
              onChange={handleSearch}
              placeholder="Search topics ..."
              value={query}
            />
          </div>
          {topics.length > 0 && (
            <div className="px-2">
              <InfiniteScroll
                dataLength={topics.length}
                hasMore={hasMoreTopics}
                loader={<Loading inline size={LoadingSize.XSmall} />}
                next={fetchMoreTopics}
                scrollableTarget="topicScrollableDiv"
                style={{
                  height: '100%',
                }}
              >
                {topics.map((topic) => (
                  <div className="text-xs" key={topic.createdAt}>
                    <SidebarLink
                      logName="individualTopicLink"
                      textColor="text-gray-50 !font-normal"
                      title={truncateString(topic.topicTitle, 30)}
                      to={`/topic/${topic.topicId}`}
                    />
                  </div>
                ))}
              </InfiniteScroll>
            </div>
          )}
        </>
      )}
    </div>
  );
};
