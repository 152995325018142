import { useState } from 'react';
import { UserInfo } from '../../models/QAmodels';
import { invokeFastApi } from '../apis/fastapi';
import { logError } from '../utils';
import { useToaster } from './toast';

export const REFERENCES_PER_PAGE = 10;

export interface ChatReferencesRequest {
  question_id: string;
  app_name?: string;
  page?: number;
  references_per_page?: number;
  query?: string;
}

export interface ChatReferenceResponse {
  id: string;
  question_id: string;
  reference_id: string;
  cited: boolean;
  rank: number;
  url?: string;
  title: string;
  created_time?: number;
  modified_time?: number;
  author?: UserInfo;
  // first_content_chunk?: string;  // v2
  snippet?: string;
  source: string;
  object_type?: string;
  extra_fields?: Record<string, never>;
}

interface UseChatReferencesReturn {
  loading: boolean;
  error: boolean;
  chatReferences: ChatReferenceResponse[];
  fetchChatReferences: (
    chatReferencesRequest: ChatReferencesRequest
  ) => Promise<void>;
  totalPages: number;
}

export const useChatReferences = (): UseChatReferencesReturn => {
  const [chatReferences, setChatReferences] = useState<ChatReferenceResponse[]>(
    []
  );

  const [totalPages, setTotalPages] = useState(0);

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const toaster = useToaster();

  const fetchChatReferences = async (
    chatReferencesRequest: ChatReferencesRequest
  ) => {
    setLoading(true);
    setError(false);
    setChatReferences([]);
    setTotalPages(0);

    try {
      const res = await invokeFastApi({
        path: '/_chatReferences',
        method: 'POST',
        body: chatReferencesRequest,
      });

      const { references, total_pages } = (await res) as {
        references: ChatReferenceResponse[];
        total_pages: number;
      };

      setChatReferences(references);
      setTotalPages(total_pages);
    } catch (fetchError) {
      logError(fetchError);
      setError(true);
      toaster.failure('Error fetching chat references');
    }

    setLoading(false);
  };

  return {
    loading,
    error,
    chatReferences,
    fetchChatReferences,
    totalPages,
  };
};
