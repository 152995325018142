import { Action } from '../actions';
import { GlobalState } from '../state';
import { AdminAction } from './actions';

export const reducer = (state: GlobalState, action: Action): GlobalState => {
  const admin = { ...state.sidebar };

  switch (action.type) {
    case AdminAction.SET_SIDEBAR_OPEN: {
      admin.open = action.payload as boolean;
      break;
    }

    case AdminAction.SET_SIDEBAR_KNOWLEDGE_OPEN: {
      admin.knowledgeOpen = action.payload as boolean;
      break;
    }

    case AdminAction.SET_SIDEBAR_ADMIN_OPEN: {
      admin.adminOpen = action.payload as boolean;
      break;
    }

    case AdminAction.SET_SIDEBAR_PERSONAL_OPEN: {
      admin.personalOpen = action.payload as boolean;
      break;
    }

    case AdminAction.SET_SIDEBAR_SETTINGS_OPEN: {
      admin.settingsOpen = action.payload as boolean;
      break;
    }
  }

  return {
    ...state,
    sidebar: admin,
  };
};
