import React, { FC, useCallback, useEffect, useState } from 'react';
import { UIButton } from '../../../../components/controls/ui/UIButton/UIButton';
import { UITextbox } from '../../../../components/controls/ui/UITextbox/UITextbox';
import { trackEvent } from '../../../../extra/sharedMethods';
import {
  getAuthProvider,
  ssoAPI,
  SSOUnsupportedError,
} from '../../../../scripts/apis';
import { requestConfirmationCode } from '../../../../scripts/apis/auth';
import { openLoginPopup } from '../../../../scripts/authentication';
import { AnalyticsEvent } from '../../../../scripts/constants/analytics-event';
import {
  QueryParams,
  useFlag,
  useHistoryPush,
  useLoginCode,
  useLoginEmail,
  useLoginProvider,
  useLoginStage,
  useLowerCase,
  useRedirectParam,
  useToaster,
} from '../../../../scripts/hooks';
import { ToastType } from '../../../../scripts/models/toast';
import { QueryPreservingLink } from '../../../../scripts/routing/QueryPerservingLink';
import { logError } from '../../../../scripts/utils';
import { AuthActionContainer } from '../../AuthActionContainer/AuthActionContainer';
import {
  GoogleAuthButton,
  handleLoginWithGoogle,
} from '../../GoogleAuthButton/GoogleAuthButton';
import { isValidLoginShortCode, ShortCodeAuth } from '../../ShortCodeAuth';

const UnsupportedErrorMessage: FC = () => {
  return (
    <div className="w-80">
      <h3 className="mt-0 mb-2.5">
        Sorry, we couldn't find an account with that email.
      </h3>
      <p className="my-0 text-cloud-40 text-xs font-normal">
        Please confirm you entered a valid work email, then contact your
        administrator if you are unable to sign in. If your company is not yet
        on Dashworks, you can book a demo at{' '}
        <a href="https://dashworks.ai" rel="noreferrer" target="_blank">
          dashworks.ai
        </a>
      </p>
    </div>
  );
};

interface LoginMethodStepProps {
  forcedSSO?: boolean;
  customDomainEmail?: string;
}

const enum Provider {
  Unknown,
  Google,
  SSO,
  Email,
}

const slugToEnumProvider = (providerSlug: string) => {
  switch (providerSlug) {
    case 'google':
      return Provider.Google;
    case 'email':
      return Provider.Email;
    case 'sso':
      return Provider.SSO;
    default:
      return Provider.Unknown;
  }
};

const isProbablyValidEmailRegex = /^.*?@.*?$/;

const enum Stage {
  None,
  CodeSent,
}

// eslint-disable-next-line max-lines-per-function
export const LoginMethodStep: FC<LoginMethodStepProps> = ({
  forcedSSO,
  customDomainEmail,
}) => {
  const [queryEmail] = useLoginEmail();
  const [queryProvider] = useLoginProvider();
  const [queryStage] = useLoginStage();
  const [email, setEmail] = useLowerCase(queryEmail);
  const [errorMessage, setErrorMessage] = useState<string | undefined>();
  const [provider, setProvider] = useState(slugToEnumProvider(queryProvider));
  const [stage, setStage] = useState(Stage.None);
  const [isSelfServe, setIsSelfServe] = useState(false);
  const toaster = useToaster();
  const [loginCode] = useLoginCode();
  const selfServeDisabled = useFlag('selfServeDisabled');
  const signupEnabled = useFlag('selfSignup');
  const signupLinkEnabled = useFlag('signupLink');
  const [redirectParam] = useRedirectParam();
  const goToSignup = useHistoryPush(
    `/signup?loginEmail=${encodeURIComponent(email)}&${
      redirectParam
        ? `${QueryParams.Redirect}=${encodeURIComponent(redirectParam)}`
        : ''
    }`
  );

  useEffect(() => {
    setProvider(slugToEnumProvider(queryProvider));
    setStage(queryStage);
    setEmail(queryEmail);
  }, [queryProvider, queryStage, queryEmail, setEmail]);

  useEffect(() => {
    if (isValidLoginShortCode(loginCode)) {
      setStage(1);
    }
  }, [loginCode]);

  const handleError = useCallback(
    (err) => {
      logError(err);

      toaster.create({
        type: ToastType.FAILURE,
        message: <UnsupportedErrorMessage />,
        timeout: 8000,
      });

      setErrorMessage(undefined);
    },
    [setErrorMessage, toaster]
  );

  const reset = useCallback(() => {
    setStage(0);
    setProvider(Provider.Unknown);
    setEmail('');
  }, [setEmail]);

  const onNextSSO = useCallback(
    (_email: string) => {
      ssoAPI
        .getProviderResponse(_email)
        .then((value) => {
          // If is custom domain, kick user over the custom domain with email arg
          if (
            value.on_prem_url &&
            !location.href.startsWith(value.on_prem_url)
          ) {
            location.href = `${
              value.on_prem_url
            }?customDomainEmail=${encodeURIComponent(_email)}`;

            return;
          }

          openLoginPopup(value.url);
        })
        .catch((error) => {
          logError(error);

          // todo: This is likely not an error state anymore. Will remove later.
          if (error instanceof SSOUnsupportedError) {
            toaster.sticky({
              dismissible: true,
              type: ToastType.FAILURE,
              message: <UnsupportedErrorMessage />,
            });

            setErrorMessage(undefined);
          }
        });
    },
    [setErrorMessage, toaster]
  );

  useEffect(() => {
    // If custom domain email is provided, we trigger the SSO flow automatically
    if (!customDomainEmail) {
      return;
    }

    setEmail(customDomainEmail);
    onNextSSO(customDomainEmail);
  }, [onNextSSO, customDomainEmail, setEmail]);

  const onNext = useCallback(() => {
    if (!email) {
      setErrorMessage('Email is required');
      return;
    }

    if (!isProbablyValidEmailRegex.test(email)) {
      setErrorMessage('Invalid email');
      return;
    }

    if (provider === Provider.Email) {
      requestConfirmationCode(email).then(
        () => {
          setStage(Stage.CodeSent);
        },
        (error) => {
          logError(error);
          toaster.failure(
            'Failed to send confirmation email, please try again.'
          );
        }
      );

      return;
    }

    if (provider === Provider.Google) {
      handleLoginWithGoogle();
      return;
    }

    getAuthProvider(email)
      .then((response) => {
        if (!response) {
          toaster.create({
            type: ToastType.FAILURE,
            message: <UnsupportedErrorMessage />,
            timeout: 8000,
          });

          return;
        }

        const { provider: resolvedProvider, domain_exists } = response;
        if (!resolvedProvider) {
          if (domain_exists || !selfServeDisabled) {
            goToSignup();
            toaster.failure(
              <>No account with this email exists! Please sign up first.</>,
              8000
            );
          } else {
            trackEvent(AnalyticsEvent.SelfServeAttempted, { email });
            setIsSelfServe(true);
            toaster.create({
              type: ToastType.FAILURE,
              message: <UnsupportedErrorMessage />,
              timeout: 8000,
            });
          }

          return;
        }

        const prov = slugToEnumProvider(resolvedProvider);
        setProvider(prov);
        if (prov === Provider.SSO) {
          onNextSSO(email);
        }
      })
      .catch(handleError);
  }, [
    email,
    provider,
    handleError,
    toaster,
    goToSignup,
    selfServeDisabled,
    onNextSSO,
  ]);

  const onEmailChanged = useCallback(
    (value: string) => {
      setEmail(value);
      setErrorMessage(undefined);
      setStage(Stage.None);
      setProvider(Provider.Unknown);
    },
    [setEmail, setErrorMessage]
  );

  return (
    <AuthActionContainer
      title="Sign in to Dashworks"
      warningMessage={
        forcedSSO &&
        'SSO is enabled for your organization, you must login with SSO.'
      }
    >
      {(provider !== Provider.Email || !queryEmail) && stage === 0 && (
        <div>
          <UITextbox
            autoComplete="email"
            error={errorMessage}
            inputClassName="emailInput"
            label="Work email"
            onChange={onEmailChanged}
            onEnter={onNext}
            placeholder="Enter your email address ..."
            size="large"
            type="email"
            value={email}
          />
          {selfServeDisabled && isSelfServe && (
            <div className="px-1 mt-1 text-mahogany-30">
              <div>
                No account exists. Please request access{' '}
                <a href="https://dashworks.ai">here</a>.
              </div>
            </div>
          )}
        </div>
      )}

      {provider === Provider.Unknown && (
        <UIButton onClick={onNext} size="large">
          Continue
        </UIButton>
      )}

      {provider === Provider.SSO && (
        <UIButton onClick={onNext} size="large" type="primary">
          Continue
        </UIButton>
      )}
      {provider === Provider.Google && (
        <GoogleAuthButton>Continue with Google</GoogleAuthButton>
      )}
      {signupEnabled && provider === Provider.Email && (
        // eslint-disable-next-line react/jsx-no-useless-fragment
        <>
          {stage === 0 ? (
            <UIButton onClick={onNext} size="large" type="primary">
              Continue with email
            </UIButton>
          ) : (
            <ShortCodeAuth loginCode={loginCode} />
          )}
        </>
      )}

      {signupLinkEnabled && (
        <span className="flex justify-between">
          {provider === Provider.Email && <a onClick={reset}>Back</a>}
          <span>
            No account?{' '}
            <QueryPreservingLink to="/signup">Sign up!</QueryPreservingLink>
          </span>
        </span>
      )}
    </AuthActionContainer>
  );
};
