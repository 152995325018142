import './BillingPage.scss';
import classNames from 'classnames';
import React, { FC, ReactNode, Suspense, useEffect, useState } from 'react';
import { useQueryParam } from 'use-query-params';
import {
  Loading,
  LoadingSize,
} from '../../../components/controls/Loading/Loading';
import { UIButton } from '../../../components/controls/ui/UIButton/UIButton';
import { UIIcon } from '../../../components/controls/ui/UIIcon/UIIcon';
import { SupportLink } from '../../../components/links/links';
import { trackEvent } from '../../../extra/sharedMethods';
import {
  hasSubscriptionTrialEnded,
  useHasValidSubscriptionPlan,
  useSubscriptionSummary,
} from '../../../hooks/subscriptionHooks';
import {
  SubscriptionPlanType,
  SubscriptionStatus,
  SubscriptionSummary,
} from '../../../models/User';
import { AnalyticsEvent } from '../../../scripts/constants/analytics-event';
import {
  createScriptLoader,
  useIsAdmin,
  useUserSafe,
} from '../../../scripts/hooks';
import { logDebug } from '../../../scripts/utils';

const useStripePricingTable = createScriptLoader(
  'https://js.stripe.com/v3/pricing-table.js'
);

export const BillingPage: FC = () => {
  const isAdmin = useIsAdmin();
  const hasValidSubscription = useHasValidSubscriptionPlan();

  if (!isAdmin && !hasValidSubscription) {
    return (
      <div className="sidebarPage billingPage">
        <h3>
          Your workspace doesn't have an active plan. Please ask workspace admin
          to setup a valid subscription plan to use Dashworks
        </h3>
      </div>
    );
  }

  if (!isAdmin) {
    return (
      <div className="sidebarPage billingPage">
        <h3>Only workspace admins can view this page</h3>
      </div>
    );
  }

  return (
    <div className="mx-2 lg:mx-auto border-transparent my-24 max-w-5xl billingPage">
      <div className="flex gap-2 items-center">
        <UIIcon name="update" size={32} />
        <h2 className="text-2xl m-0 font-bold">Billing</h2>
      </div>
      <div className="flex p-6 gap-4 rounded-lg bg-cloud-15 justify-between items-center mt-9 max-sm:p-4">
        <div className="flex flex-col gap-2.5">
          <div>
            Manage your billing information, upgrade plan, view invoices, and
            update credit card details.
          </div>
          <div>
            Have questions? Contact our <SupportLink>support</SupportLink>.
          </div>
        </div>
      </div>

      <div className="mt-10">
        <SeatCountView />
      </div>
      <hr />

      <SubscriptionByStatusView />
    </div>
  );
};

const SeatCountView: FC = () => {
  const subscription = useSubscriptionSummary();
  if (subscription?.seatCount === undefined) {
    return null;
  }

  return (
    <div className="notice">
      <UIIcon name="info" />
      Your Active Seats: <b>{subscription.seatCount}</b>
    </div>
  );
};

const SubscriptionByStatusView: FC = () => {
  const subscription = useSubscriptionSummary();
  const [stripeCheckoutSessionId] = useQueryParam<string | undefined>(
    'checkout_session_id'
  );

  useEffect(() => {
    if (
      stripeCheckoutSessionId &&
      subscription?.status === SubscriptionStatus.ACTIVE
    ) {
      logDebug('Stripe Checkout Completed', stripeCheckoutSessionId);

      trackEvent(AnalyticsEvent.SubscriptionStripeCheckoutComplete, {
        plan: subscription.planType,
        seatCount: subscription.seatCount,
      });
    }
  }, [
    subscription?.status,
    stripeCheckoutSessionId,
    subscription?.planType,
    subscription?.seatCount,
  ]);

  switch (subscription?.status) {
    case undefined:
    case SubscriptionStatus.CANCELLED:
    case SubscriptionStatus.TRIAL: {
      const trialEndDate = subscription?.trialEndAt
        ? new Date(subscription.trialEndAt)
        : undefined;

      const hasTrialEnded = subscription
        ? hasSubscriptionTrialEnded(subscription)
        : false;

      let noticeMessage: ReactNode | string | undefined;
      let noticeMessageAlert = false;
      if (subscription === undefined) {
        noticeMessage = "Your workspace doesn't have a valid subscription";
        noticeMessageAlert = true;
      } else if (trialEndDate) {
        if (hasTrialEnded) {
          noticeMessageAlert = true;
        }

        noticeMessage = (
          <>
            {hasTrialEnded ? 'Your trial has ended on ' : 'Your trial ends on '}
            <b>
              {trialEndDate.toLocaleDateString('en-US', {
                year: 'numeric',
                month: 'long',
                day: 'numeric',
              })}
            </b>
          </>
        );
      }

      return (
        <SubscriptionPlanSelectorView
          noticeMessage={noticeMessage}
          noticeMessageAlert={noticeMessageAlert}
          subscription={subscription}
        />
      );
    }

    case SubscriptionStatus.INACTIVE:

    // eslint-disable-next-line no-fallthrough
    case SubscriptionStatus.ACTIVE: {
      return (
        <div>
          <h3 className="flex items-center gap-2">
            {subscription.status === SubscriptionStatus.ACTIVE && (
              <UIIcon
                className="text-moss-30"
                name="check-circle-filled"
                size={30}
              />
            )}
            <span className="capitalize font-medium">
              {subscription.planType.toLocaleLowerCase()}
            </span>
            <span>plan selected</span>
          </h3>
          <div>
            <div className="my-5">
              {subscription.planType === SubscriptionPlanType.ENTERPRISE && (
                <div>
                  Please contact our <SupportLink /> to manage this plan.
                </div>
              )}
              {subscription.planType !== SubscriptionPlanType.ENTERPRISE && (
                <div>
                  {subscription.status === SubscriptionStatus.INACTIVE && (
                    <div className="notice alert">
                      <UIIcon name="error" />
                      Your subscription plan is inactive, please go to manage
                      billing to fix this.
                    </div>
                  )}
                  <UIButton link="/admin/billing-portal" type="secondary">
                    <UIIcon name="external" type="ui" />
                    Manage Billing
                  </UIButton>
                </div>
              )}
            </div>
          </div>
        </div>
      );
    }
  }
};

const SubscriptionPlanSelectorView: FC<{
  subscription?: SubscriptionSummary;
  noticeMessage?: ReactNode | string;
  noticeMessageAlert?: boolean;
}> = ({ subscription, noticeMessage, noticeMessageAlert = false }) => {
  const [stripeCheckoutSessionId] = useQueryParam<string | undefined>(
    'checkout_session_id'
  );

  const showPricingTable =
    !stripeCheckoutSessionId &&
    subscription?.status !== SubscriptionStatus.ACTIVE;

  const showProcessingState =
    !!stripeCheckoutSessionId &&
    subscription?.status !== SubscriptionStatus.ACTIVE;

  return (
    <div>
      {showProcessingState && (
        <div>
          <Loading
            inline
            size={LoadingSize.Small}
            text="Processing payment, please wait..."
          />
          <div className="notice">
            If it&apos;s taking longer, please try refreshing this page or
            contact our <SupportLink />.
          </div>
        </div>
      )}
      {showPricingTable && (
        <div>
          <h3>Select a Plan</h3>
          {noticeMessage && (
            <div
              className={classNames('notice', { alert: noticeMessageAlert })}
            >
              {noticeMessageAlert && <UIIcon name="error" size={20} />}
              {noticeMessage}
            </div>
          )}
          <Suspense fallback={<Loading />}>
            <PricingTableView />
          </Suspense>
        </div>
      )}
    </div>
  );
};

declare global {
  // eslint-disable-next-line vars-on-top, no-var
  var Rewardful: {
    referral: string;
  };
}

const PricingTableView: FC = () => {
  const user = useUserSafe();
  useStripePricingTable();

  const [clientReferenceId, setClientReferenceId] = useState(
    user.userId.replace(':', '_')
  );

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    if (window.Rewardful?.referral) {
      setClientReferenceId(window.Rewardful.referral);
    }
  }, []);

  return (
    <stripe-pricing-table
      client-reference-id={clientReferenceId}
      customer-email={user.email}
      pricing-table-id={STRIPE_PRICING_TABLE_ID}
      publishable-key={STRIPE_PUBLISHABLE_API_KEY}
    />
  );
};
