import { useFlags } from 'launchdarkly-react-client-sdk';
import { useMemo } from 'react';

type IntegrationFlag = `${string}Integration`;
type IntegrationControl = `${string}IntegrationControl`;
type OrgModeControl = `${string}OrgModeControl`;

export interface FeatureFlags {
  [key: IntegrationControl]: boolean;

  [key: IntegrationFlag]: boolean;

  [key: OrgModeControl]: boolean;

  searchAccessDiscovery: boolean;
  adminAnalytics: boolean;
  appFilterSuggestions: boolean;
  autocompleteGoogleSearch: boolean;
  browserExtensionSettings: boolean;
  customChatbot: boolean;
  dashAiDown: boolean;
  enableBrowserHistorySearch: boolean;
  fileTypeFilterNoticeThreshold: number;
  fileUploadsEnabled: boolean;
  notionBeta: boolean;
  notionUserAuth: boolean;
  recentDocVisits: boolean;
  searchSemantic?:
    | false
    | {
        enabled: boolean;
      };
  ssoLogin: boolean;
  selfSignup: boolean;
  signupLink: boolean;
  scheduleDemoLink: string;
  stopAnswerGeneration: boolean;
  enableServiceWorker: boolean;
  hideRelatedSearches: boolean;
  hideAskDashAiQueriesHomepage: boolean;
  enableHomepageConfigIcons: boolean;
  enterprisePricingEnabled: boolean;
  serviceAccountEnabled: boolean;
  useChatWebsocket: boolean;
  dashAiReconnectAppsEnabled: boolean;
  seeAllSourcesEnabled: boolean;
  earn500SidebarLink: boolean;
  supportMultipleLlmProviders: boolean;
  openaiChatGpt4LatestLlmDisabled: boolean;
  anthropicClaudeSonnetLlmDisabled: boolean;
  anthropicClaudeOpusLlmDisabled: boolean;
  googleGemini15LlmDisabled: boolean;
  metaLlama70BLlmDisabled: boolean;
  enableAllUsersToAddBotConnections: boolean;
  workspaceSetupDisabled: boolean;
  selfServeDisabled: boolean;
  teamPlanMultiDomainEnabled: boolean;
}

const defaults: FeatureFlags = {
  searchAccessDiscovery: false,
  adminAnalytics: false,
  appFilterSuggestions: false,
  autocompleteGoogleSearch: false,
  browserExtensionSettings: false,
  customChatbot: false,
  dashAiDown: false,
  enableBrowserHistorySearch: false,
  fileTypeFilterNoticeThreshold: 6,
  fileUploadsEnabled: false,
  notionBeta: false,
  notionUserAuth: false,
  recentDocVisits: false,
  searchSemantic: false,
  ssoLogin: false,
  selfSignup: false,
  signupLink: false,
  scheduleDemoLink: 'https://calendly.com/dashworks-gtm/discover-dashworks-2',
  stopAnswerGeneration: false,
  enableServiceWorker: false,
  hideRelatedSearches: false,
  hideAskDashAiQueriesHomepage: false,
  enableHomepageConfigIcons: false,
  enterprisePricingEnabled: false,
  serviceAccountEnabled: false,
  useChatWebsocket: false,
  dashAiReconnectAppsEnabled: false,
  seeAllSourcesEnabled: false,
  earn500SidebarLink: false,
  supportMultipleLlmProviders: false,
  openaiChatGpt4LatestLlmDisabled: false,
  anthropicClaudeSonnetLlmDisabled: false,
  anthropicClaudeOpusLlmDisabled: false,
  googleGemini15LlmDisabled: false,
  metaLlama70BLlmDisabled: false,
  enableAllUsersToAddBotConnections: false,
  workspaceSetupDisabled: false,
  selfServeDisabled: false,
  teamPlanMultiDomainEnabled: false,
};

export function useFlag<K extends keyof FeatureFlags>(
  name: K
): FeatureFlags[K] {
  return useFlags<FeatureFlags>()[name] ?? defaults[name];
}

export function useAllFlags(): Partial<FeatureFlags> {
  const rawFlags = useFlags<FeatureFlags>();
  return useMemo(
    () => ({
      ...defaults,
      ...rawFlags,
    }),
    [rawFlags]
  );
}
