import { createContext, useContext } from 'react';
import { v4 as uuidV4 } from 'uuid';
import { SendQueryArgs } from '../components/jit-qa/textInputBox/QATextInputBox';
import { EventEmitter, Listener } from '../emitter';
import { UploadedFile } from '../models/File';
import {
  AllReferencesSummary,
  ChatExtraData,
  MessageType,
  QAControllerState,
  QAMessage,
  QAPacketType,
  QAStages,
  QAStreamMessage,
  QATopic,
  Reference,
  SetStageMessageData,
  SkillRetrieved,
  StageVariables,
  StaticAnswerType,
} from '../models/QAmodels';
import { SupportedLlm } from '../models/User';
import { WorkflowModalTypes } from '../models/Workflows';
import { store } from '../redux/store';
import { logDebug, logError } from '../scripts/utils';
import { invokeFastApi } from './apis/fastapi';
import {
  QAWebSocketRequestHandler,
  StreamMessageBody,
} from './apis/qaWebSocketClient';
import { ConnectedApps } from './hooks/sortedInstantApps';
import { Answer } from './models/answers';
import { TimeoutError } from './timeout-watcher';
import { WatchedValue } from './value-watcher';

export interface GetTopics {
  page: number;
  query: string;
  fetchMore?: boolean;
}

export interface QAControllerEventArgs {
  // question
  query?: string;
  query_id?: string;

  // answer
  response_id?: string;
  response_references?: Reference[];
  response_rating?: number;

  // references
  allReferencesSummary?: AllReferencesSummary;

  answerId: string;
  answerCreateModalTitle: string;
  answerCreatePrefillQuestion: string;
  isAuthor: boolean;
  workflowModalType?: WorkflowModalTypes;
  workflowId?: string;
  workflowTemplate?: string;
  isBaseLLM?: boolean;
  skillFilters?: ConnectedApps[];
  fileFilters?: UploadedFile[];
  answerFilters?: Answer[];
  preferredLlm?: SupportedLlm | null;
}

type QAControllerEvents =
  | 'hideAppsNotifications'
  | 'saveWorkflow'
  | 'setQuery'
  | 'setQueryAndSources'
  | 'showAnswerDialog'
  | 'showFeedbackModal'
  | 'showReferencesSidecar'
  | 'showWorkflowModal'
  | 'stopGenerating';

export class QAController {
  private readonly messagesValue = new WatchedValue<QAMessage[]>([]);
  private readonly currentMessageValue = new WatchedValue<
    QAMessage | undefined
  >(undefined);

  private readonly currentTopicValue = new WatchedValue<QAMessage[]>([]);

  private readonly topicListValue = new WatchedValue<QATopic[]>([]);

  private readonly stateValue = new WatchedValue<QAControllerState>({
    isFetchingMessages: false,
    isNewTopic: true,
    currentStage: QAStages.WAIT_USER_INPUT,
    hasMoreMessages: true,
    hasMoreTopics: true,
  });
  private readonly existingRowIds = new Set<string>();

  // eslint-disable-next-line unicorn/prefer-event-target
  private readonly eventEmitter = new EventEmitter<QAControllerEventArgs>();

  public getIsNewTopic(): { isNewTopic: boolean } {
    const { isNewTopic } = this.stateValue.getValue();
    return { isNewTopic };
  }

  public setIsNewTopic(value: boolean): void {
    this.stateValue.updateObject({ isNewTopic: value });
  }

  public updateShareableConversation(
    conversation_id: string,
    visibility: 'ORG' | 'PRIVATE'
  ): void {
    this.messagesValue.applyUpdate((old) => {
      return old.map((m) => {
        if (m.conversation_id === conversation_id) {
          return { ...m, visibility };
        }

        return m;
      });
    });

    this.currentTopicValue.applyUpdate((old) => {
      return old.map((m) => {
        if (m.conversation_id === conversation_id) {
          return { ...m, visibility };
        }

        return m;
      });
    });
  }

  public getUserMessageForAssistantAnswer(
    assistantRowId: string,
    sharableConversation?: QAMessage[]
  ): QAMessage | undefined {
    const messages = sharableConversation ?? this.messagesValue.getValue();

    const assistantMessageIndex = messages.findIndex(
      (m) => m.row_id === assistantRowId
    );

    if (assistantMessageIndex === -1) {
      return;
    }

    const messagesTillAssistantMessage = messages
      .slice(0, assistantMessageIndex)
      .reverse();

    return messagesTillAssistantMessage.find((m) => m.sender === 'USER');
  }

  public async fetchHistoryMessages(reset = false): Promise<void> {
    if (reset) {
      logDebug('resetting chat messages');
      this.messagesValue.applyUpdate(() => []);
      this.stateValue.updateObject({ hasMoreMessages: true });
      this.existingRowIds.clear();
    }

    const last_fetched_ts =
      this.messagesValue.getValue()[0]?.tsSentAt ?? undefined;

    let { hasMoreMessages } = this.stateValue.getValue();
    try {
      logDebug('Fetching messages, last_fetched_ts', last_fetched_ts);
      this.stateValue.updateObject({
        isFetchingMessages: true,
      });

      const responseMessages = await this._apiFetchHistoryMessages(
        last_fetched_ts
      );

      const newMessages = responseMessages.filter(
        (m) => !this.existingRowIds.has(m.row_id)
      );

      logDebug(
        'fetched message count',
        responseMessages.length,
        'non dup messages length',
        newMessages.length
      );

      hasMoreMessages = newMessages.length > 0;
      this.messagesValue.applyUpdate((old) => newMessages.concat(old));
      for (const m of newMessages) {
        this.existingRowIds.add(m.row_id);
      }
    } finally {
      this.stateValue.updateObject({
        isFetchingMessages: false,
        hasMoreMessages,
      });
    }
  }

  public async fetchTopics({
    page,
    query,
    fetchMore = false,
  }: GetTopics): Promise<void> {
    const { topics } = await invokeFastApi<{ topics: QATopic[] }>({
      path: '/topics',
      method: 'GET',
      queryParams: { page: page.toString(), query },
    });

    if (fetchMore) {
      this.topicListValue.applyUpdate((old) => {
        return [...old, ...topics];
      });
    } else {
      this.topicListValue.applyUpdate(() => topics);
    }

    if (topics.length === 0) {
      this.stateValue.updateObject({ hasMoreTopics: false });
    } else {
      this.stateValue.updateObject({ hasMoreTopics: true });
    }
  }

  public async sendNewMessage({
    queryText,
    conversation_id,
    sources,
    llm_preference,
    continueTopic,
    bot_id,
  }: SendQueryArgs): Promise<void> {
    if (!conversation_id) {
      conversation_id = uuidV4().replace(/-/g, '');
    }

    const row_id = uuidV4().replace(/-/g, '');
    const message_id = uuidV4().replace(/-/g, '');

    const { isNewTopic } = this.getIsNewTopic();
    const sanitizedQueryText = this._sanitizeQueryText(queryText, bot_id);

    const userMessage = [
      this.buildNewMessage({
        text: sanitizedQueryText,
        sender: 'USER',
        conversation_id,
        row_id,
        message_id,
        bot_id,
      }),
    ];

    const messages = this.messagesValue.getValue();
    const addToMessages =
      messages.some((m) => m.conversation_id === conversation_id) ||
      !continueTopic;

    if (addToMessages) {
      this.messagesValue.applyUpdate((old) => old.concat(userMessage));
    }

    this.currentTopicValue.applyUpdate((old) => old.concat(userMessage));

    this.stateValue.updateObject({
      currentStage: QAStages.SENDING_REQUEST,
    });

    this.currentMessageValue.applyUpdate(() =>
      this.buildNewMessage({
        text: this.getTextMessageForStage(QAStages.SENDING_REQUEST) ?? '',
        sender: 'ASSISTANT',
        conversation_id: conversation_id!,
        message_id,
        bot_id,
        isPreFinalGeneration: true,
        progressBar: 5,
      })
    );

    const body: StreamMessageBody = {
      query: sanitizedQueryText,
      row_id,
      message_id,
      conversation_id,
      isNewTopic,
      sources,
      llm_preference,
      bot_id,
      tz_offset: new Date().getTimezoneOffset(),
    };

    const { tokens } = store.getState();
    const idToken = tokens?.loginTokens?.id_token;
    if (!idToken) {
      throw new Error('Tokens not found');
    }

    const websocket = new QAWebSocketRequestHandler<QAStreamMessage>({
      onMessage: (message: QAStreamMessage) => {
        this._processStreamMessage(
          message,
          conversation_id!,
          message_id,
          bot_id
        );
      },
      onClose: () => {
        websocket.close();
        this._finalizeStreamMessage(
          conversation_id!,
          message_id,
          bot_id,
          addToMessages
        );
      },
      onError: (e) => {
        websocket.close();
        logError(e);
        this._onError(
          getStreamErrorMessage(e),
          conversation_id!,
          message_id,
          e,
          bot_id
        );
      },
    });

    await websocket.sendMessage({
      authorization: `Bearer ${idToken}`,
      type: 'AUTH',
    });

    await websocket.sendMessage({ ...body, type: 'QUERY' });

    this.listenEvent('stopGenerating', () => {
      this._stopGeneratingMessage(
        message_id,
        conversation_id!,
        websocket,
        addToMessages,
        bot_id
      );
    });
  }

  public getTextMessageForStage(stage: QAStages): string | undefined {
    switch (stage) {
      case QAStages.WAIT_USER_INPUT: {
        return '';
      }

      case QAStages.SENDING_REQUEST: {
        return 'Understanding question...';
      }

      case QAStages.BUILDING_PROMPT: {
        return 'Building prompt...';
      }

      case QAStages.GATHERING_DATA: {
        return 'Gathering Data...';
      }

      case QAStages.STREAMING_ANSWER: {
        return 'Answering...';
      }
    }
  }

  public destruct(): void {
    // unbind all event listeners here
    this.eventEmitter.removeAllListeners();
  }

  public off(
    eventName: QAControllerEvents,
    listener: Listener<Partial<QAControllerEventArgs>>
  ): void {
    this.eventEmitter.off(eventName, listener);
  }

  public removeTopic(conversation_id: string): void {
    this.messagesValue.applyUpdate((old) => {
      return old.filter((m) => m.conversation_id !== conversation_id);
    });

    this.topicListValue.applyUpdate((old) => {
      return old.filter((m) => m.topicId !== conversation_id);
    });
  }

  public useMessages(): QAMessage[] {
    return this.messagesValue.useHook();
  }

  public useCurrentTopic(): QAMessage[] {
    return this.currentTopicValue.useHook();
  }

  public useTopics(): QATopic[] {
    return this.topicListValue.useHook();
  }

  public setCurrentTopic(value: QAMessage[]): void {
    this.currentTopicValue.applyUpdate(() => value);
  }

  public useProgressStage(): QAControllerState {
    return this.stateValue.useHook();
  }

  public useCurrentMessage(): QAMessage | undefined {
    return this.currentMessageValue.useHook();
  }

  public listenEvent(
    eventName: QAControllerEvents,
    listener: Listener<Partial<QAControllerEventArgs>>
  ): void {
    this.eventEmitter.on(eventName, listener);
  }

  public triggerEvent(
    eventName: QAControllerEvents,
    eventArgs: Partial<QAControllerEventArgs>
  ): void {
    this.eventEmitter.emit(eventName, eventArgs);
  }

  private _sanitizeQueryText(queryText: string, bot_id?: string): string {
    if (bot_id) {
      return queryText.replace(/@\[(.*?)]\([^)]+\)/, '@$1');
    }

    return queryText;
  }

  private buildNewMessage({
    text,
    sender,
    conversation_id,
    row_id,
    message_id,
    bot_id,
    messageType,
    extra_data,
    isPreFinalGeneration = false,
    progressBar,
  }: {
    text: string;
    sender: 'ASSISTANT' | 'USER';
    conversation_id: string;
    row_id?: string;
    message_id?: string;
    bot_id?: string;
    messageType?: MessageType;
    extra_data?: ChatExtraData;
    isPreFinalGeneration?: boolean;
    progressBar?: number;
  }): QAMessage {
    return {
      row_id: row_id ?? uuidV4().replace(/-/g, ''),
      message_id: message_id ?? uuidV4().replace(/-/g, ''),
      conversation_id,
      bot_id,
      conversation_timestamp: Date.now(),
      extraData: extra_data,
      sender,
      tsSentAt: Date.now(),
      messageText: text,
      messageEncoding: 'PLAIN_TEXT',
      isPreFinalGeneration,
      progressBar,
      references: [],
      allReferencesSummary: { app_references_count: [] },
      relatedSearches: [],
      debugLogs: { doc_data: {}, stages: [] },
      messageType,
      topic_title: '',
    };
  }

  private async _stopGeneratingMessage(
    message_id: string,
    conversation_id: string,
    websocket: QAWebSocketRequestHandler<QAStreamMessage>,
    addToMessages = true,
    bot_id?: string
  ) {
    await websocket.sendMessage({ type: 'STOP_GENERATION' });
    websocket.close();
    if (this.stateValue.getValue().currentStage === QAStages.STREAMING_ANSWER) {
      this._finalizeStreamMessage(
        conversation_id,
        message_id,
        bot_id,
        addToMessages
      );

      return;
    }

    this.currentMessageValue.applyUpdate(() => {
      return this.buildNewMessage({
        text: 'Your request has been stopped. Feel free to ask another question at any time!',
        sender: 'ASSISTANT',
        conversation_id,
        message_id,
        bot_id,
        extra_data: { staticAnswerType: StaticAnswerType.STOPPED_GENERATION },
      });
    });

    this._finalizeStreamMessage(conversation_id, message_id, bot_id);
  }

  private _finalizeStreamMessage(
    conversation_id: string,
    message_id: string,
    bot_id?: string,
    addToMessages = true
  ) {
    logDebug('_finalizeStreamMessage called');
    this.eventEmitter.removeAllListenersForEvent('stopGenerating');
    const message = this.currentMessageValue.getValue();
    if (message) {
      message.conversation_id = conversation_id;
      message.message_id = message_id;
      message.bot_id = bot_id;
      message.isPreFinalGeneration = false;
      if (addToMessages) {
        this.messagesValue.applyUpdate((old) => old.concat([message]));
      }

      const isInTopics = this.topicListValue
        .getValue()
        .some((t) => t.topicId === conversation_id);

      if (!isInTopics && message.topic_title) {
        this.topicListValue.applyUpdate((old) => {
          return [
            {
              topicId: conversation_id,
              topicTitle: message.topic_title!,
              createdAt: message.conversation_timestamp,
            },
            ...old,
          ];
        });
      }

      this.currentTopicValue.applyUpdate((old) => old.concat([message]));
    } else {
      logDebug('No current message to finalize');
    }

    this.currentMessageValue.applyUpdate(() => undefined);

    this.stateValue.applyUpdate((old) => {
      return {
        ...old,
        currentStage: QAStages.WAIT_USER_INPUT,
        stageTextMessage: undefined,
      };
    });
  }

  private _processStreamMessage(
    _message: QAStreamMessage,
    conversation_id: string,
    message_id: string,
    bot_id?: string
  ) {
    switch (_message.type) {
      case QAPacketType.SET_STAGE: {
        const data = _message.data as SetStageMessageData;
        this.stateValue.applyUpdate((old) => {
          return {
            ...old,
            currentStage: data.stage,
            stageTextMessage: data.displayText,
          };
        });

        if (data.stage !== QAStages.WAIT_USER_INPUT) {
          this.currentMessageValue.applyUpdate(
            (old) => {
              if (old) {
                let { progressBar } = old;
                if (progressBar) {
                  progressBar += 15;
                } else {
                  progressBar = 50;
                }

                if (progressBar > 80) {
                  progressBar = 90;
                }

                return this.buildNewMessage({
                  text: data.displayText ?? 'Generating Answer...',
                  sender: 'ASSISTANT',
                  conversation_id,
                  message_id,
                  bot_id,
                  isPreFinalGeneration: true,
                  progressBar,
                });
              }
            }
            // TODO: put appropriate fallback text for display text
          );
        }

        if (data.stage === QAStages.STREAMING_ANSWER) {
          this.currentMessageValue.applyUpdate(() => {
            return this.buildNewMessage({
              text: '',
              sender: 'ASSISTANT',
              conversation_id,
              message_id,
              bot_id,
              messageType: data.answerType,
              isPreFinalGeneration: false,
              progressBar: 100,
            });
          });
        }

        break;
      }

      case QAPacketType.CHUNK: {
        const messageText = _message.data as string;
        this.currentMessageValue.applyUpdate((old) => {
          if (old) {
            return {
              ...old,
              messageText: old.messageText.concat(messageText),
              skillRetrieved: undefined,
            };
          }

          return this.buildNewMessage({
            text: messageText,
            sender: 'ASSISTANT',
            conversation_id,
            message_id,
            bot_id,
            isPreFinalGeneration: false,
          });
        });

        break;
      }

      case QAPacketType.WEB_VIEW_URLS: {
        const { urls } = _message.data as { urls: Reference[] };
        this.currentMessageValue.applyUpdate((old) => {
          if (old) {
            return {
              ...old,
              references: old.references.concat(urls),
            };
          }
        });

        break;
      }

      case QAPacketType.ALL_WEB_VIEW_URLS_SUMMARY: {
        const { all_references_summary } = _message.data as {
          all_references_summary: AllReferencesSummary;
        };

        this.currentMessageValue.applyUpdate((old) => {
          if (old) {
            return {
              ...old,
              allReferencesSummary: all_references_summary,
            };
          }
        });

        break;
      }

      case QAPacketType.SKILL_RETRIEVED: {
        const skillsRetrieved = _message.data as SkillRetrieved;
        this.currentMessageValue.applyUpdate((old) => {
          if (old) {
            let { progressBar } = old;
            if (progressBar) {
              progressBar += 15;
            } else {
              progressBar = 50;
            }

            if (progressBar > 80) {
              progressBar = 90;
            }

            return {
              ...old,
              messageText: '',
              skillRetrieved: skillsRetrieved,
              progressBar,
            };
          }
        });

        break;
      }

      case QAPacketType.RELATED_SEARCHES: {
        const { suggested_queries } = _message.data as {
          suggested_queries: string[];
        };

        this.currentMessageValue.applyUpdate((old) => {
          if (old) {
            return {
              ...old,
              relatedSearches: old.relatedSearches.concat(suggested_queries),
            };
          }
        });

        break;
      }

      case QAPacketType.TOPIC_TITLE: {
        const { topic_title } = _message.data as {
          topic_title: string;
        };

        this.currentMessageValue.applyUpdate((old) => {
          if (old) {
            return {
              ...old,
              topic_title,
            };
          }
        });

        break;
      }

      case QAPacketType.DEBUG_LOGS: {
        const {
          debug_logs: { stages, execution_time, doc_data },
        } = _message.data as {
          debug_logs: {
            stages: StageVariables[];
            execution_time?: number;
            doc_data: Record<string, never>;
          };
        };

        this.currentMessageValue.applyUpdate((old) => {
          if (old) {
            return {
              ...old,
              debugLogs: {
                stages: old.debugLogs?.stages.concat(stages) ?? [],
                execution_time,
                doc_data,
              },
            };
          }
        });

        break;
      }

      case QAPacketType.ROW_ID: {
        const { row_id } = _message.data as {
          row_id: string;
        };

        this.currentMessageValue.applyUpdate((old) => {
          if (old) {
            return {
              ...old,
              row_id,
            };
          }
        });

        break;
      }
    }
  }

  private async _apiFetchHistoryMessages(
    older_than_ts?: number
  ): Promise<QAMessage[]> {
    const { history } = await invokeFastApi<{ history: QAMessage[] }>({
      path: '/_chatHistory',
      method: 'GET',
      queryParams: {
        scroll_timestamp: older_than_ts?.toString(),
        api_version: '2',
      },
    });

    return history;
  }

  private _onError(
    error: string,
    conversation_id: string,
    message_id: string,
    e: unknown,
    bot_id?: string
  ) {
    logDebug('stream _onError', error);
    this.currentMessageValue.applyUpdate(() => {
      return this.buildNewMessage({
        text: error,
        sender: 'ASSISTANT',
        conversation_id,
        message_id,
        bot_id,
        isPreFinalGeneration: false,
      });
    });

    this._finalizeStreamMessage(conversation_id, message_id, bot_id);

    // refetch chat history for timeout error
    if (e instanceof TimeoutError) {
      this.fetchHistoryMessages(true);
    }
  }
}

const getStreamErrorMessage = (e: unknown): string => {
  if (e instanceof TimeoutError) {
    return 'A timeout occurred while reading response, please reload the page and try again';
  }

  return 'Error in streaming the response';
};

const context = createContext<QAController | undefined>(undefined);

export const useQAController = (): QAController => {
  const ctx = useContext(context);
  if (!ctx) {
    throw new Error('Attempted to use context outside of scope');
  }

  return ctx;
};

export const QAControllerProvider = context.Provider;
