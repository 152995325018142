import './OpenSidebar.scss';
import classNames from 'classnames';
import React, { useCallback } from 'react';
import { useSidebarOpen } from '../../../../scripts/hooks';
import { useContrast } from '../../../../scripts/hooks/colors';
import { useIsHomepage } from '../../../../scripts/hooks/location';
import { toggleSidebar } from '../../../../scripts/sidebar';
import { CompanyLogo } from '../../../controls/CompanyLogo/CompanyLogo';
import { UIIcon } from '../../../controls/ui/UIIcon/UIIcon';

export const OpenSidebar: React.FC = () => {
  const sidebarOpen = useSidebarOpen();
  const isHomepage = useIsHomepage();

  const onOpenClick = useCallback(() => {
    toggleSidebar(true);
  }, []);

  const openSidebarClasses = classNames('openSidebar z-[130]', {
    hidden: sidebarOpen,
    contrast: useContrast() && isHomepage,
    'md:bg-white md:shadow': !isHomepage,
  });

  return (
    <div className={openSidebarClasses} onClick={onOpenClick}>
      <CompanyLogo />
      <UIIcon name="menu" size={18} />
    </div>
  );
};
