import { ReactNode } from 'react';
import { AppName } from '../apps/definition';
import { AppState } from '../constants';
import { OnboardingStep } from '../scripts/constants/onboarding-step';
import { Workspace } from './workspace';

interface OnboardingState {
  first_admin: boolean;
  step: OnboardingStep | null;
}

export type BackgroundType = 'color' | 'image';

export interface OrgPreferences {
  background_allow_user?: boolean;
  background_color?: string;
  background_type?: BackgroundType;
  background_image?: string;
  logo?: string;
  answers: boolean;
  auto_invite_members?: boolean;
  override_new_tab_page?: boolean;
}

export interface SocialAppUsers {
  email: string;
  icon: string;
  name: string;
}

interface RecommendedAppsList {
  name: AppName;
  count: number;
  social_info_list: SocialAppUsers[];
}

export interface AppPreferences {
  allowed_apps?: string[];
  blocked_apps?: string[];
  recommended_apps_list?: RecommendedAppsList[];
}

export interface OrgByOrg {
  id: string;
  domain: string;
  name: string;
  firstAdminOnboarded: boolean;
  homepageLinks: Link[];
  exploToken: string | null;
  preferences: OrgPreferences;
  appPreferences: AppPreferences;
  isWorkspaceSetup: boolean;
  chatRetentionDays: number | null;
  preferredLlm: SupportedLlm | null;
  disclaimer: string;
  dashSubscriptionsByOrgDomain: { nodes: SubscriptionSummary[] };
  allowedDomains: { nodes: AllowedDomain[] };
  usersAppsByOrg: {
    nodes: UserApp[];
  };
}

export interface AllowedDomain {
  domain: string;
  orgId?: string;
  status: DomainVerificationStatus;
  verificationCode: string;
  createdAt: number;
  allowAllSignups?: boolean;
}

export enum DomainVerificationStatus {
  VERIFIED = 'VERIFIED',
  UNVERIFIED = 'UNVERIFIED',
  VERIFICATION_FAILED = 'VERIFICATION_FAILED',
}

export interface SubscriptionSummary {
  planType: SubscriptionPlanType;
  status: SubscriptionStatus;
  id: string;
  trialEndAt?: string | null;
  seatCount?: number;
}

export enum SubscriptionStatus {
  ACTIVE = 'ACTIVE',
  TRIAL = 'TRIAL',
  INACTIVE = 'INACTIVE',
  CANCELLED = 'CANCELLED',
}

export enum SubscriptionPlanType {
  TEAM = 'TEAM',
  BUSINESS = 'BUSINESS',
  ENTERPRISE = 'ENTERPRISE',
}

// eslint-disable-next-line import/no-unused-modules
export enum Role {
  CSuite = 'c_suite',
  ITOrHR = 'it_hr',
  ProductEngineeringDesign = 'product_engineering_design',
  SalesMarketingSupport = 'sales_marketing_support',
  Other = 'other',
}

export enum SupportedLlm {
  OPENAI_GPT4_OMNI = 'openai-gpt4-omni',
  OPENAI_CHATGPT4_LATEST = 'chatgpt-4o-latest',
  OPENAI_GPT4_TURBO = 'openai-gpt4-turbo',
  ANTHROPIC_CLAUDE_SONNET = 'anthropic-claude-sonnet',
  ANTHROPIC_CLAUDE_OPUS = 'anthropic-claude-opus',
  GOOGLE_GEMINI_1_5 = 'google-gemini-1.5',
  META_LLAMA_70B = 'meta-llama-70B',
}

export interface UserPreferences {
  background?: UserBackgroundPreferences | null;
  role?: Role;
}

interface UserBackgroundPreferences {
  color?: string;
  type?: BackgroundType;
  image?: string;
}

interface Link {
  icon: string;
  title: string;
  link: string;
}

export interface User {
  email: string;
  sub: string;
  aclKey: string;
  admin: boolean;
  userId: string;
  nodeId: string;
  onboardingState: OnboardingState;
  hasSeenWebOnboarding: boolean;
  homepageLinks: Link[];
  usersAppsByOrg: {
    nodes: unknown;
  };
  orgByOrgId: OrgByOrg;
  usersApps: {
    nodes: UserApp[];
  };
  // TODO: This will not be optional later
  preferences?: UserPreferences;
  preferredLlm: SupportedLlm | null;
  reconnectedApps: Record<AppName, number>;
  workspace?: Workspace;
}

export interface UserApp {
  appName: AppName;
  appUserId: string;
  botOrgWideAccess: string;
  createdAt: string;
  displayName: string;
  extra: {
    base_id?: string;
    table_id?: string;
  };
  email: string;
  userId: string;
  statusCode: AppState;
  statusMessage: string;
  org: string;
  id: number;
  syncedFrom: string;
  syncedUntil: string;
  workspaceId: string;
  workspaceName?: string;
  connectionName: string;
}

export interface AppConnectionStatus {
  appName: AppName;
  displayName: string;
  shortName: AppName;
  description: ReactNode | string;
  statusCode: AppState;
  id: number;
  isOrg: boolean;
  isInstant: boolean;
  showInAppStore: boolean;
  workspaceName?: string;
  connectionName?: string;
}

export interface SkillFilter {
  app_id: number;
  app_name: AppName;
}

export interface Source<T> {
  search_all: boolean;
  filters: T[] | null;
}

export interface Sources {
  skills: Source<SkillFilter>;
  files: Source<string>;
  answers: Source<string>;
}
