import './LeenaChatBotScript.scss';
import { FC, useEffect } from 'react';
import SwiggyChatbotIcon from '../../../assets/images/swiggy_chatbot.png';
import { useFlag } from '../../../scripts/hooks';

declare const chatteron: { init: (arg0: { clientId: string }) => void };

export const LeenaChatBotBubble: FC<{
  className?: string;
}> = () => {
  const customChatbot = useFlag('customChatbot');
  useEffect(() => {
    const leenaScript = document.getElementById('leena-script');

    if (!leenaScript && customChatbot) {
      const script = document.createElement('script', {});

      script.addEventListener('load', () => {
        chatteron.init({
          clientId: CHATTERON_CLIENT_ID,
        });
      });

      script.src = 'https://web.leena.ai/scripts/sdk.js';
      script.id = 'leena-script';
      script.async = true;
      document.body.append(script);

      // HACK: This is to make sure a 'load' event is fired after the script is loaded.
      const interval = setInterval(() => {
        const elements = document.getElementsByClassName('chatBubble');
        if (elements.length > 0) {
          dispatchEvent(new Event('load'));
          clearInterval(interval);
        }

        const botImageElements = document.getElementsByClassName('botImage');
        if (botImageElements.length > 0 && botImageElements[0]) {
          const image = botImageElements[0] as HTMLImageElement;
          image.src = SwiggyChatbotIcon;
        }
      }, 500);
    } else if (leenaScript && !customChatbot) {
      leenaScript.remove();
      location.reload();
    }
  }, [customChatbot]);

  return null;
};
