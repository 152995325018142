import React, { ReactNode } from 'react';
import { useHasEnterpriseFeatures } from '../../../hooks/subscriptionHooks';
import { EnterpriseIcon } from '../../admin/EnterprisePaywall/EnterprisePaywall';
import { useBound, useId } from '../../pageSearch/results/misc/hooks';

interface RadioListOption<T> {
  value: T;
  displayName: string;
  icon?: ReactNode;
  additionalMetadata?: ReactNode;
  enterpriseOnly?: boolean;
}

interface RadioListRowProps<T> extends RadioListOption<T> {
  selected: boolean;
  name: string;
  listItemClass: string;
  nonEnterpriseOnlyListItemClass: string;
  onSelect(value: T): void;
  setPaywallModalOpen(): void;
}

interface RadioListProps<T> {
  options: RadioListOption<T>[];
  selected: T;
  listItemClass?: string;
  nonEnterpriseOnlyListItemClass?: string;
  onChange(value: T): void;
  setPaywallModalOpen(): void;
}

const RadioListRow = <T,>({
  displayName,
  selected,
  value,
  name,
  icon,
  additionalMetadata,
  enterpriseOnly,
  listItemClass,
  nonEnterpriseOnlyListItemClass,
  onSelect,
  setPaywallModalOpen,
}: RadioListRowProps<T>) => {
  const handleSelected = useBound(onSelect, value);
  const hasEnterpriseFeatures = useHasEnterpriseFeatures();

  return (
    <label
      className={`py-1.5 px-3 flex items-center gap-2 ${listItemClass} ${
        hasEnterpriseFeatures || !enterpriseOnly
          ? nonEnterpriseOnlyListItemClass
          : ''
      }`}
      onClick={() => {
        if (hasEnterpriseFeatures || !enterpriseOnly) {
          return;
        }

        setPaywallModalOpen();
      }}
    >
      {hasEnterpriseFeatures || !enterpriseOnly ? (
        <input
          checked={selected}
          className="w-4 h-4 m-0 mr-3 leading-6"
          name={name}
          onChange={handleSelected}
          type="radio"
        />
      ) : (
        <EnterpriseIcon size={10} />
      )}
      <div
        className={!hasEnterpriseFeatures && enterpriseOnly ? 'opacity-40' : ''}
      >
        {icon}
      </div>
      <div
        className={!hasEnterpriseFeatures && enterpriseOnly ? 'opacity-40' : ''}
      >
        {displayName}
      </div>
      {additionalMetadata}
    </label>
  );
};

export const RadioList = <T,>({
  options,
  selected,
  listItemClass = '',
  nonEnterpriseOnlyListItemClass = '',
  onChange,
  setPaywallModalOpen,
}: RadioListProps<T>): JSX.Element => {
  const listId = useId();
  return (
    <div className="flex flex-col">
      {options.map((opt) => (
        <RadioListRow
          additionalMetadata={opt.additionalMetadata}
          displayName={opt.displayName}
          enterpriseOnly={opt.enterpriseOnly}
          icon={opt.icon}
          key={opt.displayName}
          listItemClass={listItemClass}
          name={listId}
          nonEnterpriseOnlyListItemClass={nonEnterpriseOnlyListItemClass}
          onSelect={onChange}
          selected={opt.value === selected}
          setPaywallModalOpen={setPaywallModalOpen}
          value={opt.value}
        />
      ))}
    </div>
  );
};
