import classNames from 'classnames';
import React, { FC, useEffect, useState } from 'react';
import { Bot, SlackbotChannel } from '../../models/Bots';
import { UpdateSlackbotChannelParams } from '../../scripts/hooks/bots';
import { Modal } from '../controls/ui/Modal/Modal';
import { UIButton } from '../controls/ui/UIButton/UIButton';
import { UIIcon } from '../controls/ui/UIIcon/UIIcon';
import { BotsSelectButtonSettings } from '../general/botsSelect/BotsSelectSettings';

export const ChannelsModal: FC<{
  bots: Bot[];
  channel: SlackbotChannel;
  closeModal: () => void;
  updateSlackbotChannel: (params: UpdateSlackbotChannelParams) => Promise<void>;
}> = ({ bots, channel, closeModal, updateSlackbotChannel }) => {
  const [botId, setBotId] = useState(channel.bot_id);
  const [isAutoRespond, setIsAutoRespond] = useState(channel.is_autorespond);
  const [respondOnlyIfAnswerFound, setRespondOnlyIfAnswerFound] = useState(
    channel.respond_only_if_answer_found
  );

  useEffect(() => {
    if (!isAutoRespond) {
      setRespondOnlyIfAnswerFound(false);
    }
  }, [isAutoRespond]);

  return (
    <Modal onClose={closeModal}>
      <div className="flex flex-col gap-6">
        <div className="flex gap-2 items-center">
          <UIIcon name="cog" size={32} />
          <h2 className="text-2xl m-0 font-bold">Edit Channel</h2>
        </div>

        <div>
          <div className="font-bold">Channel Name</div>
          {channel.channel_name}
        </div>

        <div>
          <div className="font-bold">Bot</div>
          <BotsSelectButtonSettings
            botId={botId}
            bots={bots}
            setBotId={setBotId}
          />
        </div>

        <div className="flex flex-col gap-3">
          <div className="flex gap-2 items-start">
            <input
              checked={isAutoRespond}
              className="mt-1.5"
              onChange={() => {
                setIsAutoRespond(!isAutoRespond);
              }}
              onClick={stopPropagation}
              type="checkbox"
            />
            <div>
              <div className="font-bold">Autorespond</div>
              <div className="text-gray-50 text-sm">
                Automatically respond to messages in the channel that are
                questions and not directed towards specific individuals.
              </div>
            </div>
          </div>
          <div
            className={classNames('flex gap-2 items-start', {
              'opacity-50': !isAutoRespond,
            })}
          >
            <input
              checked={respondOnlyIfAnswerFound}
              className="mt-1.5"
              onChange={() => {
                if (!isAutoRespond) {
                  return;
                }

                setRespondOnlyIfAnswerFound(!respondOnlyIfAnswerFound);
              }}
              onClick={stopPropagation}
              type="checkbox"
            />
            <div>
              <div className="font-bold">Reply only if answer found</div>
              <div className="text-gray-50 text-sm">
                The bot will only respond to questions it knows the answer to
                and ignore the rest.
              </div>
            </div>
          </div>
        </div>
      </div>

      <footer className="flex justify-between mt-6">
        <UIButton onClick={closeModal} type="secondary">
          Cancel
        </UIButton>
        <UIButton
          disabled={
            botId === channel.bot_id &&
            isAutoRespond === channel.is_autorespond &&
            respondOnlyIfAnswerFound === channel.respond_only_if_answer_found
          }
          onClick={async () => {
            await updateSlackbotChannel({
              team_id: channel.team_id,
              channel_id: channel.channel_id,
              bot_id: botId,
              is_autorespond: isAutoRespond,
              respond_only_if_answer_found: respondOnlyIfAnswerFound,
            });

            closeModal();
          }}
          type="primary"
        >
          Confirm
        </UIButton>
      </footer>
    </Modal>
  );
};

const stopPropagation = (event: React.SyntheticEvent) => {
  event.stopPropagation();
};
