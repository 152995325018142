export const DEFAULT_SIDEBAR_STATE: SidebarState = {
  open: true,
  knowledgeOpen: true,
  adminOpen: true,
  personalOpen: true,
  settingsOpen: false,
};

export interface SidebarState {
  open: boolean;
  knowledgeOpen: boolean;
  adminOpen: boolean;
  personalOpen: boolean;
  settingsOpen: boolean;
}
