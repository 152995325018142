import classNames from 'classnames';
import './SourcesFilterSwitch.scss';
import React, {
  ReactNode,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import { trackEvent } from '../../extra/sharedMethods';
import { Bot } from '../../models/Bots';
import { UploadedFile } from '../../models/File';
import { UserApp } from '../../models/User';
import { AnalyticsEvent } from '../../scripts/constants/analytics-event';
import { useUserSafe } from '../../scripts/hooks';
import { ConnectedApps } from '../../scripts/hooks/sortedInstantApps';
import { Answer } from '../../scripts/models/answers';
import {
  checkAiOnlyModeFromSources,
  checkWebModeFromSources,
} from '../../scripts/sources/common';
import { isSquareImg } from '../../scripts/utils';
import { UIIcon } from '../controls/ui/UIIcon/UIIcon';
import { UITooltip } from '../controls/ui/UIToolTip/UIToolTip';
import { Tabs, TabsList, TabsTrigger } from '../shadcn/lib/components/tabs';
import { BotKnowledgeDisplay } from './BotKnowledgeDisplay/BotKnowledgeDisplay';
import { KnowledgeSelect } from './KnowledgeSelect/KnowledgeSelect';

type TSourcesFilterTabNames = 'aiOnly' | 'knowledge' | 'web';

interface ISourcesFilterSwitchProps {
  isBaseLLM: boolean;
  skillFilters: ConnectedApps[];
  fileFilters: UploadedFile[];
  answerFilters: Answer[];
  setIsBaseLLM: React.Dispatch<React.SetStateAction<boolean>>;
  setSkillFilters: React.Dispatch<React.SetStateAction<ConnectedApps[]>>;
  setFileFilters: React.Dispatch<React.SetStateAction<UploadedFile[]>>;
  setAnswerFilters: React.Dispatch<React.SetStateAction<Answer[]>>;
  allSkills: ConnectedApps[];
  allSkillsUserApps: UserApp[];
  allFiles: UploadedFile[];
  allAnswers: Answer[];
  bot?: Bot;
  botsEnabledApps?: ConnectedApps[];
  botsEnabledUserApps?: UserApp[];
  showPopupFromBottom?: boolean;
  error?: ReactNode;
}

// eslint-disable-next-line max-lines-per-function
export const SourcesFilterSwitch: React.FC<ISourcesFilterSwitchProps> = ({
  isBaseLLM,
  skillFilters,
  fileFilters,
  answerFilters,
  setIsBaseLLM,
  setSkillFilters,
  setFileFilters,
  setAnswerFilters,
  allSkills,
  allSkillsUserApps,
  allFiles,
  allAnswers,
  bot,
  botsEnabledApps,
  botsEnabledUserApps,
  showPopupFromBottom = false,
  error,
}) => {
  const {
    orgByOrgId: {
      preferences: { logo },
    },
  } = useUserSafe();

  const [tab, setTab] = useState<TSourcesFilterTabNames>(
    getTabValue(skillFilters, isBaseLLM, bot)
  );

  const [isMultiSelectOpen, setIsMultiSelectOpen] = useState(false);
  const selectRef = useRef<HTMLDivElement>(null);
  const chevronRef = useRef<SVGElement>(null);

  const [isSquareLogo, setIsSquareLogo] = useState<boolean>(false);

  const handleTabChange = useCallback((value: string) => {
    setTab(value as TSourcesFilterTabNames);
  }, []);

  const toggleIsMultiSelectOpen = useCallback(() => {
    setIsMultiSelectOpen(!isMultiSelectOpen);
  }, [isMultiSelectOpen, setIsMultiSelectOpen]);

  const setSourceFiltersToKnowledge = useCallback(() => {
    if (skillFilters.some((app) => app.appName === 'web') || isBaseLLM) {
      setIsBaseLLM(false);
      setSkillFilters(allSkills);
      setFileFilters(allFiles);
      setAnswerFilters(allAnswers);
    }

    trackEvent(AnalyticsEvent.QAAppsFilterButtonClicked, {
      isBaseLLM,
      searchFilters: [...skillFilters, ...fileFilters, ...answerFilters],
    });
  }, [
    skillFilters,
    isBaseLLM,
    fileFilters,
    answerFilters,
    setIsBaseLLM,
    setSkillFilters,
    allSkills,
    setFileFilters,
    allFiles,
    setAnswerFilters,
    allAnswers,
  ]);

  const setSourceFiltersToWeb = useCallback(() => {
    setIsBaseLLM(false);
    setSkillFilters([{ appName: 'web', displayName: 'Web' }]);
    setFileFilters([]);
    setAnswerFilters([]);

    trackEvent(AnalyticsEvent.QAWebFilterButtonClicked);
  }, [setAnswerFilters, setFileFilters, setIsBaseLLM, setSkillFilters]);

  const setSourceFiltersToAiOnly = useCallback(() => {
    setIsBaseLLM(true);
    setSkillFilters([]);
    setFileFilters([]);
    setAnswerFilters([]);

    trackEvent(AnalyticsEvent.QAAiOnlyFilterButtonClicked);
  }, [setAnswerFilters, setFileFilters, setIsBaseLLM, setSkillFilters]);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        selectRef.current &&
        !selectRef.current.contains(event.target as Node) &&
        chevronRef.current &&
        !chevronRef.current.contains(event.target as Node)
      ) {
        setIsMultiSelectOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  useEffect(() => {
    let isMounted = true;

    const checkIfSquareLogo = async () => {
      if (isMounted && logo) {
        const res = await isSquareImg(logo);
        setIsSquareLogo(res);
      } else {
        setIsSquareLogo(false);
      }
    };

    checkIfSquareLogo();

    return () => {
      isMounted = false;
    };
  }, [logo]);

  useEffect(() => {
    setTab(getTabValue(skillFilters, isBaseLLM, bot));
  }, [skillFilters, isBaseLLM, bot]);

  return (
    <>
      <Tabs onValueChange={handleTabChange} value={tab}>
        <TabsList
          className={classNames('grid w-fit grid-cols-3', {
            'border-mahogany-10': !!error,
          })}
        >
          <TabsTrigger
            className="relative"
            disabled={!!bot && tab !== 'knowledge'}
            onMouseDown={setSourceFiltersToKnowledge}
            value="knowledge"
          >
            {isMultiSelectOpen && (
              <>
                <div className="overlay" />
                <div
                  className={`knowledgeSelect text-black ${
                    showPopupFromBottom ? 'popupFromBottom' : 'popupFromTop'
                  }`}
                  ref={selectRef}
                >
                  {bot && botsEnabledApps && botsEnabledUserApps ? (
                    <BotKnowledgeDisplay
                      allAnswers={allAnswers}
                      allFiles={allFiles}
                      bot={bot}
                      botsEnabledApps={botsEnabledApps}
                      botsEnabledUserApps={botsEnabledUserApps}
                    />
                  ) : (
                    <KnowledgeSelect
                      allAnswers={allAnswers}
                      allFiles={allFiles}
                      allSkills={allSkills}
                      allSkillsUserApps={allSkillsUserApps}
                      answerFilters={answerFilters}
                      fileFilters={fileFilters}
                      setAnswerFilters={setAnswerFilters}
                      setFileFilters={setFileFilters}
                      setSkillFilters={setSkillFilters}
                      skillFilters={skillFilters}
                    />
                  )}
                </div>
              </>
            )}

            {isSquareLogo ? (
              <UITooltip title="Search Apps, Files, and Answers">
                <div className="rounded flex">
                  <img alt="favicon" height={16} src={logo} width={16} />
                </div>
              </UITooltip>
            ) : (
              <UIIcon
                name="app"
                stroke
                tooltip="Search Apps, Files, and Answers"
              />
            )}
            <div
              className={`ml-1 sm:block hidden text-cloud-40 ${
                tab === 'knowledge' ? '!text-black' : ''
              }`}
            >
              Apps
            </div>
            {(allSkills.length > 0 ||
              allFiles.length > 0 ||
              allAnswers.length > 0 ||
              bot) && (
              <UITooltip title="Select Apps, Files or Answers to search">
                <UIIcon
                  className="selectChevron hover:pt-1"
                  name="arrow-down"
                  onClick={toggleIsMultiSelectOpen}
                  ref={chevronRef}
                />
              </UITooltip>
            )}
          </TabsTrigger>

          <TabsTrigger
            disabled={!!bot && tab !== 'web'}
            onMouseDown={setSourceFiltersToWeb}
            value="web"
          >
            <UIIcon name="web" size={16} tooltip="Search Web" type="apps" />
            <div
              className={`ml-1 sm:block hidden text-cloud-40 ${
                tab === 'web' ? '!text-black' : ''
              }`}
            >
              Web
            </div>
          </TabsTrigger>

          <TabsTrigger
            disabled={!!bot && tab !== 'aiOnly'}
            onMouseDown={setSourceFiltersToAiOnly}
            value="aiOnly"
          >
            <UIIcon name="ai-sparkle" tooltip="Ask AI without search" />
            <div
              className={`ml-1 sm:block hidden text-cloud-40 ${
                tab === 'aiOnly' ? '!text-black' : ''
              }`}
            >
              AI Only
            </div>
          </TabsTrigger>
        </TabsList>
      </Tabs>
      {/* Only show this if the error is an actual node */}
      {error && error !== true && (
        <div className="text-sm mt-[5px] text-mahogany-20">{error}</div>
      )}
    </>
  );
};

const getTabValue = (
  skillFilters: ConnectedApps[],
  isBaseLLM: boolean,
  bot?: Bot
) => {
  if (bot) {
    if (checkAiOnlyModeFromSources(bot.sources)) return 'aiOnly';
    if (checkWebModeFromSources(bot.sources)) return 'web';
    return 'knowledge';
  }

  if (isBaseLLM) return 'aiOnly';
  if (skillFilters.some((app) => app.appName === 'web')) return 'web';
  return 'knowledge';
};
