/* eslint-disable @cspell/spellchecker */

export const futureApps: [RegExp, string][] = [
  [/\baha\b/i, 'Aha'],
  [/\bamplitude\b/i, 'Amplitude'],
  [/\bbit ?bucket\b/i, 'Bitbucket'],
  [/\bcanva\b/i, 'Canva'],
  [/\bchurn ?zero\b/i, 'ChurnZero'],
  [/\bcirrus\b/i, 'Cirrus'],
  [/\bfield ?wire\b/i, 'Fieldwire'],
  [/\bfigma\b/i, 'Figma'],
  [/\bfresh ?desk\b/i, 'Freshdesk'],
  [/\bgong\b/i, 'Gong'],
  [/\bguru\b/i, 'Guru'],
  [/\bhalp\b/i, 'Halp'],
  [/\bhappy ?fox\b/i, 'HappyFox'],
  [/\blattice\b/i, 'Lattice'],
  [/\blimechat\b/i, 'LimeChat'],
  [/\blinear\b/i, 'Linear'],
  [/\blooker\b/i, 'Looker'],
  [/\bloom\b/i, 'Loom'],
  [/\blucid ?chart\b/i, 'Lucidchart'],
  [/\boned ?rive\b/i, 'OneDrive'],
  [/\bpro ?core\b/i, 'Procore'],
  [/\bretool\b/i, 'Retool'],
  [/\bslab\b/i, 'Slab'],
  [/\bsmart ?sheet\b/i, 'Smartsheet'],
  [/\bsnow ?flake\b/i, 'Snowflake'],
  [/\bwhats ?app\b/i, 'WhatsApp'],
  [/\bworkable\b/i, 'Workable'],
  [/\bzing?(hr)?\b/i, 'ZingHR'],
  [/\bzoom\b/i, 'Zoom'],
];
