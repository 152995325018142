import React, { FC } from 'react';
import { useFlag } from '../../../scripts/hooks';
import { Modal } from '../../controls/ui/Modal/Modal';
import { UIButton } from '../../controls/ui/UIButton/UIButton';
import { UIIcon } from '../../controls/ui/UIIcon/UIIcon';

interface EnterprisePaywallProps {
  cardClasses?: string;
}

export const EnterprisePaywall: FC<EnterprisePaywallProps> = ({
  cardClasses,
}: EnterprisePaywallProps) => {
  const scheduleDemoLink = useFlag('scheduleDemoLink');

  return (
    <div className="absolute top-0 left-0 right-0 bottom-0 backdrop-blur-sm bg-[#B3B3B366] bg-opacity-90 flex justify-center items-start">
      <div
        className={`bg-purple-50 p-6 rounded-lg mx-10 max-w-3xl w-full z-50 ${
          cardClasses ?? ''
        }`}
      >
        <div className="flex flex-col items-center sm:px-20">
          <div className="flex p-2 bg-sunrise-10 rounded-full">
            <UIIcon className="text-white" name="star" type="ui" />
          </div>
          <div className="text-lg font-semibold mt-3">
            Enterprise Exclusive Feature
          </div>
          <div className="whitespace-normal	text-center">
            Upgrade to the Enterprise Plan to unlock the full potential of Dash
            AI. This gives you access to features like SSO, Analytics, Insights,
            and HRIS
          </div>
          <div className="mt-6 flex gap-4">
            <UIButton href="https://www.dashworks.ai/pricing" type="secondary">
              View plans
            </UIButton>
            <UIButton href={scheduleDemoLink} type="primary">
              Contact sales
            </UIButton>
          </div>
        </div>
      </div>
    </div>
  );
};

export const EnterpriseIcon = ({
  size = 12,
}: {
  size?: number;
}): JSX.Element => (
  <div className="flex p-1 bg-sunrise-10 rounded-full mr-2">
    <UIIcon className="text-white" name="star" size={size} type="ui" />
  </div>
);

const BulletPoint = ({ description }: { description: string }) => {
  return (
    <div className="flex gap-2 items-center justify-between">
      <div>{description}</div>
      <UIIcon name="check" />
    </div>
  );
};

export const PaywallModal: React.FC<{
  closeModal: () => void;
  modalClasses?: string;
  title?: string;
}> = ({
  closeModal,
  modalClasses,
  title = 'Upgrade and unlock the full potential of Dash AI.',
}) => {
  const scheduleDemoLink = useFlag('scheduleDemoLink');

  return (
    <Modal
      hasMaxZIndex
      modalClasses={`max-w-sm p-8 ${modalClasses ?? ''}`}
      onClose={closeModal}
    >
      <div className="flex flex-col gap-4">
        <div className="w-8 flex p-2 bg-sunrise-10 rounded-full">
          <UIIcon className="text-white" name="star" type="ui" />
        </div>

        <div className="font-semibold text-2xl">{title}</div>
        <BulletPoint description="Multiple connections per app" />
        <BulletPoint description="Org-wide Dash AI training" />
        <BulletPoint description="HRIS integration" />
        <BulletPoint description="Custom LLM selection" />
        <BulletPoint description="Custom Dash AI disclaimer" />
        <BulletPoint description="Detailed admin analytics and insights" />
        <BulletPoint description="SSO and SCIM" />
        <BulletPoint description="Prioritized new integration requests" />
        <div className="flex justify-between">
          <UIButton onClick={closeModal} type="secondary">
            Cancel
          </UIButton>
          <UIButton href={scheduleDemoLink} type="primary">
            Contact Sales
          </UIButton>
        </div>
      </div>
    </Modal>
  );
};
